import React from 'react';

import closeIcon from '../../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';

export default function NoTitleWarningPopup({ onClose }) {
  return (
    <div className={classes.NoTitleWarningPopup}>
      <div className={classes.backdrop} />
      <div className={classes.popup}>
        <i className={classes.closeIcon} onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </i>
        <span>Whoops!</span>
        <p>Your meeting needs a name</p>
      </div>
    </div>
  );
}
