import axios from 'axios';

const UserService = {
  async getMe() {
    const response = await axios.get('/users/me');
    return response.data;
  },
  async updateMe(values) {
    const response = await axios.patch('/users/me', values);
    return response.data;
  },
  async uploadPhoto(image) {
    const form = new FormData();
    form.append('file', image);
    const response = await axios.post('/users/me/photo', form);
    return response.data;
  },
  async deletePhoto() {
    const response = await axios.delete('/users/me/photo');
    return response.data;
  },
};

export default UserService;
