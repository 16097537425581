import React from 'react';

import Answer from './Answer';

import classes from './styles.module.scss';

export default function Question({ question }) {
  return (
    <div className={classes.Question}>
      <div className={classes.questionContainer}>
        <p>{question.text}</p>
        <span className={classes.responseCount}>
          {question.Answers?.length}{' '}
          {question.Answers?.length === 1 ? 'response' : 'responses'}
        </span>
      </div>
      <div className={classes.answers}>
        {question.Answers?.map((answer) => (
          <Answer key={answer.id} answer={answer} />
        ))}
      </div>
    </div>
  );
}
