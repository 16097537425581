/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import TimeAgo from 'javascript-time-ago';

import usericon from '../../assets/images/usericon.webp';
import deleteIcon from '../../assets/images/delete.svg';
import { STATIC_URL } from '../../constants/main';
import NotificationService from '../../services/NotificationService';
import classes from './styles.module.scss';

export default function Notification({ notification, refetch }) {
  const navigate = useNavigate();

  const timeAgo = new TimeAgo('en-US');

  const { mutate: deleteNotification } = useMutation(
    NotificationService.deleteNotification,
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
        // notifyError(error?.response?.data?.message);
      },
    }
  );

  let meetingLink = `/my-meetings/${notification?.Meeting?.id}`;

  if (
    notification.textContent.includes('processed and ready to share') ||
    notification.textContent.includes('assigned a section') ||
    notification.textContent.includes('completed a section for meeting')
  ) {
    meetingLink = `/my-meetings/${notification?.Meeting?.id}/edit`;
  } else if (notification.textContent.includes('responded to a question')) {
    meetingLink = `/my-meetings/${notification?.Meeting?.id}?responses=true`;
  }

  return (
    <div className={classes.Notification}>
      <div className={classes.left}>
        <div className={classes.userpic}>
          <img
            src={
              notification.senderUser?.photoPath
                ? STATIC_URL + notification.senderUser?.photoPath
                : usericon
            }
            alt="User"
          />
        </div>

        <div className={classes.userInfo}>
          <h2 className={classes.userName}>{notification?.senderUser.name}</h2>
          <h2 className={classes.userTitle}>
            {notification?.senderUser.title}
          </h2>
        </div>
      </div>

      <div className={classes.textContent}>{notification.textContent}</div>

      <div className={classes.right}>
        {notification?.Meeting?.videoPath && (
          <div
            className={classes.meetingPreview}
            onClick={() => navigate(meetingLink)}
          >
            <video
              src={STATIC_URL + notification?.Meeting?.videoPath}
              alt="preview"
            />
          </div>
        )}

        <div className={classes.createdAt}>
          {timeAgo.format(new Date(notification.createdAt), 'mini-now')}
        </div>

        <button
          type="button"
          onClick={() => {
            deleteNotification(notification.id);
          }}
        >
          <img src={deleteIcon} alt="Delete" />
        </button>
      </div>
    </div>
  );
}
