import axios from 'axios';

const CommentService = {
  async createComment({ meetingId, textContent, commentId, meetingTime }) {
    const response = await axios.post('/comments', {
      meetingId,
      commentId,
      textContent,
      meetingTime,
    });
    return response.data;
  },
  async toggleLike({ commentId }) {
    const response = await axios.post(`/comments/${commentId}/like`);
    return response.data;
  },
  async deleteComment({ commentId }) {
    const response = await axios.delete(`/comments/${commentId}`);
    return response.data;
  },
  async updateComment({ commentId, textContent }) {
    const response = await axios.patch(`/comments/${commentId}`, {
      textContent,
    });
    return response.data;
  },
};

export default CommentService;
