/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Select({
  disabled,
  displayedValue,
  options,
  handleClick,
  textAlign = 'center',
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const selectRef = useRef();
  useOnClickOutside(selectRef, () => setIsOptionsListVisible(false));

  return (
    <div className={classes.root} ref={selectRef}>
      <button
        type="button"
        style={{ justifyContent: textAlign }}
        className={classNames(classes.select, {
          [classes.disabled]: disabled,
        })}
        onClick={() => {
          if (disabled) {
            return;
          }

          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {displayedValue}
      </button>
      {isOptionsListVisible && (
        <ul className={classes.options}>
          {options.map((option, index) => {
            return (
              <li
                key={option.value}
                style={{ justifyContent: textAlign }}
                onClick={() => {
                  handleClick(option, index);
                  setIsOptionsListVisible(false);
                }}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
