/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import singleTextField from '../../../assets/images/create-question/singleTextField.svg';
import uncheckedCheckbox from '../../../assets/images/uncheckedCheckbox.svg';
import checkedCheckbox from '../../../assets/images/checkedCheckbox.svg';
import uncheckedRadio from '../../../assets/images/uncheckedRadio.svg';
import checkedRadio from '../../../assets/images/checkedRadio.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const options = [
  {
    value: 'Single Text Field',
    icon: <img src={singleTextField} alt="single text field" />,
    activeIcon: <img src={singleTextField} alt="single text field" />,
  },
  {
    value: 'Multiple Choice',
    icon: <img src={uncheckedCheckbox} alt="multiple choice" />,
    activeIcon: <img src={checkedCheckbox} alt="multiple choice" />,
  },
  {
    value: 'Single Choice',
    icon: <img src={uncheckedRadio} alt="single choice" />,
    activeIcon: <img src={checkedRadio} alt="single choice" />,
  },
];

export default function Dropdown({ questionOption, setQuestionOption }) {
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setAreOptionsVisible(false));

  const currentOption = options.find(
    (option) => option.value === questionOption
  );

  return (
    <div className={classes.Dropdown} ref={dropdownRef}>
      <div
        className={classes.selector}
        onClick={() => setAreOptionsVisible((prevState) => !prevState)}
      >
        <div className={classes.iconContainer}>{currentOption.activeIcon}</div>
        {currentOption.value}
      </div>
      {areOptionsVisible && (
        <ul className={classes.optionsList}>
          {options.map((option) => {
            if (option.value !== questionOption) {
              return (
                <li
                  key={option.value}
                  onClick={() => {
                    setQuestionOption(option.value);
                    setAreOptionsVisible(false);
                  }}
                >
                  <div
                    className={classNames(
                      classes.iconContainer,
                      classes.iconBackground
                    )}
                  >
                    {option.icon}
                  </div>
                  {option.value}
                </li>
              );
            }
            return null;
          })}
        </ul>
      )}
    </div>
  );
}
