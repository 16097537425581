import React from 'react';

import infoIcon from '../../assets/images/infoIcon.svg';
import Tooltip from '../Tooltip';

import classes from './styles.module.scss';

export default function InfoIcon({ text, width }) {
  return (
    <div className={classes.InfoIcon}>
      <Tooltip text={text} width={width}>
        <img src={infoIcon} alt="info" />
      </Tooltip>
    </div>
  );
}
