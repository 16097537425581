import React from 'react';
import Modal from 'react-bootstrap/Modal';

import CancelButton from '../../components/Buttons/CancelButton';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

import classes from './styles.module.scss';

export default function ConfirmActionModal({
  title = 'Whoa wait!',
  show,
  handleClose,
  message,
  onConfirm,
  noButtons,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ConfirmActionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4858 15.502L12.9836 9.99986L18.4858 4.49768C19.3095 3.67392 19.3095 2.33835 18.4858 1.51458C17.662 0.69081 16.3264 0.69081 15.5027 1.51458L10.0005 7.01675L4.49831 1.51458C3.67455 0.69081 2.33898 0.69081 1.51521 1.51458C0.691438 2.33835 0.691439 3.67392 1.51521 4.49768L7.01738 9.99986L1.51521 15.502C0.691439 16.3258 0.691438 17.6614 1.51521 18.4851C2.33898 19.3089 3.67455 19.3089 4.49831 18.4851L10.0005 12.983L15.5027 18.4851C16.3264 19.3089 17.662 19.3089 18.4858 18.4851C19.3095 17.6614 19.3095 16.3258 18.4858 15.502Z"
            fill="black"
          />
        </svg>
      </i>
      <div className={classes.content}>
        <h3>{title}</h3>
        <p>{message}</p>
        {!noButtons && (
          <div className={classes.buttons}>
            <CancelButton onClick={handleClose} />

            <ConfirmButton
              onClick={() => {
                onConfirm();
                handleClose();
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
