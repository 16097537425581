export const timezones = {
  '-10:00': {
    value: 'Pacific/Honolulu',
    label: '(UTC-10) HST Hawaii Standard Time - Honolulu',
    offset: '-10:00',
  },
  '-09:00': {
    value: 'America/Adak',
    label: '(UTC-9) HDT Hawaii-Aleutian Daylight Time - Adak',
    offset: '-09:00',
  },
  '-08:00': {
    value: 'America/Anchorage',
    label: '(UTC-8) AKDT Alaska Daylight Time - Anchorage',
    offset: '-08:00',
  },
  '-07:00': {
    value: 'America/Phoenix',
    label: '(UTC-7) MST Mountain Standard Time - Phoenix',
    offset: '-07:00',
  },
  '-06:00': {
    value: 'America/Denver',
    label: '(UTC-6) MDT Mountain Daylight Time - Salt Lake City',
    offset: '-06:00',
  },
  '-05:00': {
    value: 'America/Chicago',
    label: '(UTC-5) CDT Central Daylight Time - Chicago',
    offset: '-05:00',
  },
  '-04:00': {
    value: 'America/New_York',
    label: '(UTC-4) EDT Eastern Daylight Time - New York',
    offset: '-04:00',
  },
};
