/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useLayoutEffect,
} from 'react';

import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import MeetingVideoPlayer from '../../components/Meeting/MeetingVideoPlayer';
import Navigation from '../../components/Navigation';
import Header from '../../components/Header';
import PreviewSections from '../../components/PreviewSections';
import Comments from '../../components/Meeting/Comments';
import SignIn from '../../components/Meeting/SignIn';
import QuestionModal from '../../modals/QuestionModal';
import { hasAuthData } from '../../helpers/authStorage';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

let videoTimer;
let waveFormTimer;
let animationId;
let videoNotesTimer;

export default function Meeting({
  meeting,
  accessPassword,
  getSharedMeeting,
  preview,
  email,
}) {
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [previousShownQuestions, setPreviousShownQuestions] = useState([]);
  const [questionToShow, setQuestionToShow] = useState({});
  const [isSignUpPopupVisible, setIsSignUpPopupVisible] = useState(false);
  const [audioSectionsWithoutBackground, setAudioSectionsWithoutBackground] =
    useState([]);
  const [
    currentAudioSectionWithoutBackground,
    setCurrentAudioSectionWithoutBackground,
  ] = useState(null);
  const [currentAudioSectionProgress, setCurrentAudioSectionProgress] =
    useState(0);
  const [isSectionsVisible, setIsSectionsVisible] = useState(true);
  const [notes, setNotes] = useState([]);
  const [previousShownNote, setPreviousShownNote] = useState({});
  const [isDraggingProgressBar, setIsDraggingProgressBar] = useState(false);

  const { id } = useParams();

  const videoRef = useRef();
  const waveFormContainerRef = useRef();
  const progressBarRef = useRef();
  const previewSectionsRef = useRef();

  const isAuthenticated = hasAuthData();
  const navigate = useNavigate();
  const {
    setRedirectAfterLogin,
    showViewerNotesModal,
    setIsSignInHintVisible,
  } = useContext(UiContext);

  const { data, refetch, isError } = useQuery(
    ['meeting', id],
    () => MeetingService.getMeeting(id),
    {
      enabled: !meeting,
      keepPreviousData: true,
      retry: false,
    }
  );

  const refetchMeeting = () => {
    if (meeting) {
      getSharedMeeting({ password: accessPassword });

      return;
    }

    refetch();
  };

  if (!isAuthenticated && isError) {
    setRedirectAfterLogin(`/my-meetings/${id}`);
    navigate('/sign-in');
  }

  useLayoutEffect(() => {
    if (previewSectionsRef) {
      const { bottom } = previewSectionsRef.current.getBoundingClientRect();
      const offset = Math.abs(window.innerHeight - bottom);

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }, [previewSectionsRef]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsSignInHintVisible(true);
    } else {
      setIsSignInHintVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (data && data.notes) {
      showViewerNotesModal(data.notes);
    }
  }, [data, showViewerNotesModal]);

  useEffect(() => {
    if (data) {
      setQuestions(
        data.Sections?.filter((section) => section.type === 'question')?.map(
          (section) => section.Question
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (meeting) {
      setQuestions(
        meeting.Sections?.filter((section) => section.type === 'question')?.map(
          (section) => section.Question
        )
      );
    }
  }, [meeting]);

  useEffect(() => {
    if (data || meeting) {
      const dataSource = meeting || data;

      const nts = [];

      dataSource.Sections.forEach((section) => {
        if (section.type === 'note') {
          nts.push(section);
        }
      });

      setNotes(nts);
    }
  }, [meeting, data]);

  useEffect(() => {
    if (email) {
      MeetingService.confirmMeetingViewAnonymously({ meetingId: id, email });
    } else {
      MeetingService.confirmMeetingView(id);
    }
  }, [id, email]);

  const { data: user, refetch: refetchUser } = useQuery(
    'me',
    UserService.getMe,
    {
      enabled: !meeting,
    }
  );

  useEffect(() => {
    if (user && meeting) {
      getSharedMeeting({ password: accessPassword, userId: user.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const animateProgressBar = useCallback(() => {
    setVideoProgress(
      (100 / videoRef.current?.duration) * videoRef.current?.currentTime
    );

    animationId = requestAnimationFrame(animateProgressBar);
  }, []);

  const stopAnimation = () => {
    cancelAnimationFrame(animationId);
  };

  useEffect(() => {
    return () => {
      stopAnimation();
    };
  }, [animateProgressBar, videoRef]);

  useEffect(() => {
    const videoRefCurrent = videoRef?.current;

    const handleLoadMetadata = () => {
      setVideoDuration(videoRef.current.duration);
    };
    if (videoRefCurrent) {
      videoRefCurrent.addEventListener('loadedmetadata', handleLoadMetadata);
    }

    return () => {
      if (videoRefCurrent) {
        videoRefCurrent.removeEventListener(
          'loadedmetadata',
          handleLoadMetadata
        );
      }
    };
  }, []);

  useEffect(() => {
    const calculateCurrentAudioSectionProgress = (
      currentTime,
      currentAudioSection
    ) => {
      const sectionCurrentTime = currentTime - currentAudioSection.startTime;
      const sectionProgress =
        (1 / currentAudioSection.section.duration) * sectionCurrentTime;

      return Math.min(Math.abs(sectionProgress), 1);
    };

    const showWaveForm = () => {
      if (!audioSectionsWithoutBackground.length) {
        return;
      }

      const currentTime = videoRef.current?.currentTime;

      const currentAudioSection = audioSectionsWithoutBackground.find(
        (sect) => {
          return (
            sect.startTime - 0.3 <= currentTime && sect.endTime >= currentTime
          );
        }
      );

      if (currentAudioSection) {
        setCurrentAudioSectionWithoutBackground(currentAudioSection);
        const sectionProgress = calculateCurrentAudioSectionProgress(
          currentTime,
          currentAudioSection
        );
        setCurrentAudioSectionProgress(sectionProgress);
      } else {
        setCurrentAudioSectionWithoutBackground(null);
      }
    };

    waveFormTimer = setInterval(() => {
      showWaveForm();
    }, 50);

    return () => {
      clearInterval(waveFormTimer);
    };
  }, [audioSectionsWithoutBackground, videoDuration]);

  // Show question modal when needed
  useEffect(() => {
    const watchVideoTime = () => {
      clearInterval(videoTimer);
      videoTimer = setInterval(() => {
        // const { currentTime } = videoRef.current;
        const currentTime = videoRef.current?.currentTime;

        const currentQuestion = questions.find(
          (question) =>
            question?.timestamp.toFixed(2) === currentTime?.toFixed(2)
        );

        if (currentQuestion) {
          const previousShownQuestionsIds = previousShownQuestions.map(
            (question) => question.id
          );

          if (!previousShownQuestionsIds.includes(currentQuestion.id)) {
            videoRef.current.pause();

            if (!user) {
              setIsSignUpPopupVisible(true);
            } else {
              setQuestionToShow(currentQuestion);
              setIsQuestionModalVisible(true);
            }
          }
        }
      });
    };

    const clearTimer = () => clearInterval(videoTimer);

    if (videoRef.current) {
      videoRef.current.addEventListener('play', watchVideoTime);
      videoRef.current.addEventListener('pause', clearTimer);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', watchVideoTime);
        videoRef.current.removeEventListener('pause', clearTimer);
      }
    };
  }, [videoRef, questions, previousShownQuestions, meeting, user]);

  useEffect(() => {
    const watchVideoTime = () => {
      clearInterval(videoNotesTimer);
      videoNotesTimer = setInterval(() => {
        // const { currentTime } = videoRef.current;
        const currentTime = videoRef.current?.currentTime;

        const currentNote = notes.find(
          (note) => note?.timestamp.toFixed(1) === currentTime?.toFixed(1)
        );

        if (currentNote && currentNote.id !== previousShownNote.id) {
          videoRef.current.pause();
          showViewerNotesModal(currentNote.text, () => videoRef.current.play());
          setPreviousShownNote(currentNote);
        }
      });
    };

    const clearTimer = () => clearInterval(videoNotesTimer);

    if (videoRef.current) {
      videoRef.current.addEventListener('play', watchVideoTime);
      videoRef.current.addEventListener('pause', clearTimer);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', watchVideoTime);
        videoRef.current.removeEventListener('pause', clearTimer);
      }
    };
  }, [videoRef, notes, showViewerNotesModal, previousShownNote]);

  // Get audiosections without background image and calculate time intervals
  useEffect(() => {
    if (!data) {
      return;
    }

    const getAudioSectionStartTime = (audioSection, sections) => {
      if (audioSection.position === 0) {
        return 0;
      }

      const prevSections = sections.slice(0, audioSection.position);
      const startTime = prevSections.reduce((acc, curVal) => {
        return acc + curVal.duration;
      }, 0);

      return startTime;
    };

    const getAudioSectionEndTime = (audioSection, sectionStartTime) => {
      const endTime = sectionStartTime + audioSection.section.duration;

      return endTime;
    };

    const getAudioSectionsTimeIntervals = (sections) => {
      const audioSections = [];
      sections.forEach((section, index) => {
        if (section.type === 'audio' && !section.audioImagePath) {
          audioSections.push({
            section,
            position: index,
          });
        }
      });

      const sectionsWithTimeIntervals = [];

      audioSections.forEach((section) => {
        const startTime = getAudioSectionStartTime(section, sections);

        const isLastSection =
          sections[sections.length - 1].id === section.section.id;

        const endTime = isLastSection
          ? getAudioSectionEndTime(section, startTime) + 10
          : getAudioSectionEndTime(section, startTime);
        sectionsWithTimeIntervals.push({
          section: section.section,
          startTime,
          endTime,
        });
      });

      return sectionsWithTimeIntervals;
    };

    if (data) {
      setAudioSectionsWithoutBackground(
        getAudioSectionsTimeIntervals(data?.Sections)
      );
    }
  }, [data]);

  const progressBarHandler = useCallback(
    (event) => {
      const progressBar = progressBarRef.current;
      const clickPosition =
        event.clientX - progressBar.getBoundingClientRect().left;
      const progressBarWidth = progressBar.offsetWidth;
      const percentage = clickPosition / progressBarWidth;
      const currentTime = percentage * videoDuration;

      videoRef.current.currentTime = currentTime;
      setPreviousShownQuestions([]);
      setPreviousShownNote({});

      animateProgressBar();
    },
    [videoDuration, animateProgressBar]
  );

  useEffect(() => {
    if (!isSignUpPopupVisible) {
      refetchUser();
    }
  }, [isSignUpPopupVisible, refetchUser]);

  useEffect(() => {
    let isPausedWhileDragging = false;

    const handleMouseMove = (event) => {
      if (!isDraggingProgressBar) {
        return;
      }

      if (!videoRef.current.paused) {
        videoRef.current.pause();
        isPausedWhileDragging = true;
      }

      progressBarHandler(event);
    };

    const handleMouseUp = () => {
      if (isDraggingProgressBar && isPausedWhileDragging) {
        videoRef.current.play();
      }

      setIsDraggingProgressBar(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDraggingProgressBar, progressBarHandler]);

  const hideQuestionModal = () => {
    setIsQuestionModalVisible(false);
    setPreviousShownQuestions((prevQuestions) => [
      ...prevQuestions,
      questionToShow,
    ]);

    setTimeout(() => {
      setQuestionToShow({});

      if (videoRef.current) {
        videoRef.current.currentTime -= 0.01;
        videoRef.current.play();
      }
    }, 200);
  };

  const rewindVideoByComment = (meetingTime) => {
    videoRef.current.currentTime = meetingTime;

    if (videoRef.current.duration !== meetingTime) {
      videoRef.current.play();
    }

    animateProgressBar();
  };

  const commentsSource = meeting || data;

  if (isSignUpPopupVisible) {
    return <SignIn handleClose={() => setIsSignUpPopupVisible(false)} />;
  }

  return (
    <div className={classes.Meeting}>
      <div className={classes.headerContainer}>
        <Header hasUserBlock={isAuthenticated} />
        <Navigation />
      </div>
      <MeetingVideoPlayer
        currentAudioSectionWithoutBackground={
          currentAudioSectionWithoutBackground
        }
        currentAudioSectionProgress={currentAudioSectionProgress}
        meeting={meeting}
        waveFormContainerRef={waveFormContainerRef}
        videoRef={videoRef}
        isDraggingProgressBar={isDraggingProgressBar}
        progressBarRef={progressBarRef}
        progressBarHandler={progressBarHandler}
        setIsDraggingProgressBar={setIsDraggingProgressBar}
        videoProgress={videoProgress}
        videoDuration={videoDuration}
        data={data}
        setPreviousShownQuestions={setPreviousShownQuestions}
        setPreviousShownNote={setPreviousShownNote}
        animateProgressBar={animateProgressBar}
        isSectionsVisible={isSectionsVisible}
        setIsSectionsVisible={setIsSectionsVisible}
        stopAnimation={stopAnimation}
      />
      {isSectionsVisible && (
        <PreviewSections
          data={meeting || data}
          videoRef={videoRef}
          animateProgressBar={animateProgressBar}
          previewSectionsRef={previewSectionsRef}
          setQuestionToShow={setQuestionToShow}
          setIsQuestionModalVisible={setIsQuestionModalVisible}
          setPreviousShownQuestions={setPreviousShownQuestions}
          setPreviousShownNote={setPreviousShownNote}
        />
      )}
      {!preview && (
        <Comments
          commentsSource={commentsSource}
          questions={questions}
          user={user}
          refetchMeeting={refetchMeeting}
          videoRef={videoRef}
          id={id}
          setIsSignUpPopupVisible={setIsSignUpPopupVisible}
          rewindVideoByComment={rewindVideoByComment}
          isAuthenticated={isAuthenticated}
        />
      )}
      <QuestionModal
        show={isQuestionModalVisible}
        handleClose={hideQuestionModal}
        question={questionToShow}
        refetchMeeting={refetchMeeting}
        preview={preview}
      />
    </div>
  );
}
