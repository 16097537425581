import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { useMutation, useQuery } from 'react-query';

import infoIcon from '../../assets/images/my-meetings/info.svg';
import deleteIcon from '../../assets/images/delete.svg';
import downloadIcon from '../../assets/images/my-meetings/download.svg';
import playIcon from '../../assets/images/my-meetings/play.svg';
import editIcon from '../../assets/images/my-meetings/edit.svg';
import shareIcon from '../../assets/images/my-meetings/share.svg';

import { SocketContext } from '../../context';
import { UiContext } from '../../context/UiContext';
import localTimeZone from '../../helpers/localTimeZone';
import downloadVideo from '../../helpers/downloadVideo';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import classes from './styles.module.scss';

export default function MeetingsPage() {
  const [page, setPage] = useState(1);
  const [processingMeetings, setProcessingMeetings] = useState([]);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [processingStep, setProcessingStep] = useState('Processing');

  const displayStatuses = useMemo(
    () => ({
      'in progress': processingStep,
      published: 'Ready to share',
      draft: 'Draft',
    }),
    [processingStep]
  );

  const { socket } = useContext(SocketContext);
  const { showConfirmActionModal } = useContext(UiContext);

  const { data, refetch } = useQuery(
    ['meetings', page],
    () => MeetingService.getMeetings(page),
    {
      keepPreviousData: true,
      refetchInterval: processingMeetings.length ? 2500 : 0,
    }
  );
  const { data: user } = useQuery('me', UserService.getMe);
  const { mutate: deleteMeetingMutation } = useMutation(
    MeetingService.deleteMeeting,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const navigate = useNavigate();

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  useEffect(() => {
    const getProcessingProgress = ({ progress, step }) => {
      setProcessingProgress(progress);
      setProcessingStep(step);
    };

    socket.on('processingProgress', getProcessingProgress);

    return () => {
      socket.removeListener('processingProgress', getProcessingProgress);
    };
  }, [socket]);

  useEffect(() => {
    if (data) {
      setProcessingMeetings(
        data.filter((meeting) => meeting.status === 'in progress')
      );
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (
      processingMeetings.some((prcssMeeting) => {
        const currentMeetingData = data.find(
          (meeting) => meeting.id === prcssMeeting.id
        );
        return currentMeetingData?.status === 'published';
      })
    ) {
      showConfirmActionModal({
        message: 'Your meeting is ready to share.',
        title: '... and done!',
        noButtons: true,
      });
    }
  }, [data, processingMeetings, showConfirmActionModal]);

  useEffect(() => {
    if (data?.rows) {
      if (data.rows.length === 0 && page !== 1) {
        setPage((prevPage) => prevPage - 1);
      }
    }
  }, [data?.rows, page]);

  const timezoneOffset = moment.tz(Date.now(), localTimeZone).format('Z');

  return (
    <div className={classes.MeetingsPage}>
      <Header hasUserBlock />
      <div className={classes.container}>
        <Navigation />
        {data?.length ? (
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Owner</th>
                  <th>Role</th>
                  <th>Duration</th>
                  <th>Date Published</th>
                  <th>File Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((meeting) => {
                    const isMeetingExpired =
                      meeting.expiresAt &&
                      new Date(meeting.expiresAt) < new Date();

                    const isNotAllowedToDelete =
                      // meeting.userId !== user.id ||
                      meeting.status === 'in progress';

                    const isNotAllowedToDownload =
                      meeting.status !== 'published' ||
                      meeting.userId !== user?.id;

                    const isNotAllowedToView =
                      meeting.status !== 'published' ||
                      (isMeetingExpired && meeting.userId !== user?.id);

                    const isNotAllowedToEdit =
                      (meeting.userId !== user?.id &&
                        meeting.userRole !== 'contributor' &&
                        meeting.userRole !== 'contributor-closed') ||
                      meeting.status === 'in progress';

                    const isNotAllowedToShare =
                      meeting.userId !== user?.id ||
                      meeting.status !== 'published';

                    const isProcessingCompleted = processingProgress === 100;

                    let share = '-';

                    let expirationInfo;

                    if (isMeetingExpired) {
                      expirationInfo = 'Meeting expired';
                    } else if (!meeting.expiresAt) {
                      expirationInfo = '-';
                    } else {
                      expirationInfo = `${moment(meeting.expiresAt).format(
                        'M-D-YY h:mm A'
                      )} (UTC${timezoneOffset}) ${localTimeZone.split('/')[1]}`;
                    }

                    if (meeting.plannedToShareAt) {
                      share = moment(meeting.plannedToShareAt).format('M-D-YY');
                    }

                    if (meeting.sharedAt) {
                      share = moment(meeting.sharedAt).format('M-D-YY');
                    }

                    return (
                      <tr>
                        <td className={classes.title}>
                          {!isNotAllowedToEdit ? (
                            <Link to={`/my-meetings/${meeting.id}/edit`}>
                              {meeting.title}
                            </Link>
                          ) : (
                            meeting.title
                          )}
                        </td>
                        <td>
                          {meeting.userId === user?.id
                            ? 'Me'
                            : meeting.ownerName}
                        </td>
                        <td className={classes.capitalize}>
                          {meeting?.userRole === 'contributor-closed'
                            ? 'contributor'
                            : meeting?.userRole}
                        </td>
                        {meeting?.duration ? (
                          <td>
                            {meeting?.duration < 60
                              ? `${parseInt(meeting?.duration, 10)} sec`
                              : `${Math.round(
                                  parseInt(meeting?.duration, 10) / 60
                                )} min`}
                          </td>
                        ) : (
                          <td />
                        )}
                        <td>{moment(meeting.updatedAt).format('M-D-YY')}</td>
                        <td className={classes.status}>
                          {displayStatuses[meeting.status]}
                          {meeting.status === 'in progress' && (
                            <div className={classes.outerProgressBar}>
                              <div className={classes.progressBar}>
                                <div
                                  className={classes.progress}
                                  style={{
                                    width: `${processingProgress}%`,
                                    borderTopRightRadius:
                                      isProcessingCompleted && '19px',
                                    borderBottomRightRadius:
                                      isProcessingCompleted && '19px',
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                        <td className={classes.buttons}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {share !== '-' && (
                                  <>
                                    <span>Shared: {share}</span>
                                    <br />
                                  </>
                                )}
                                {expirationInfo !== '-' && (
                                  <>
                                    <span>Expiration: {expirationInfo}</span>
                                    <br />
                                  </>
                                )}
                                Permission:{' '}
                                {meeting.access === 'private'
                                  ? 'Restricted'
                                  : 'Anyone with a link'}
                              </Tooltip>
                            }
                          >
                            <button type="button">
                              <img src={infoIcon} alt="info" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <button
                              disabled={isNotAllowedToDelete}
                              type="button"
                              onClick={() => {
                                showConfirmActionModal({
                                  message:
                                    'Are you sure you want to delete this meeting?',
                                  onConfirm: () =>
                                    deleteMeetingMutation(meeting.id),
                                });
                              }}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Download</Tooltip>}
                          >
                            <button
                              disabled={isNotAllowedToDownload}
                              type="button"
                              onClick={() => downloadVideo(meeting)}
                              className={classes.downloadButton}
                            >
                              <img src={downloadIcon} alt="download" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Play</Tooltip>}
                          >
                            <button
                              disabled={isNotAllowedToView}
                              type="button"
                              onClick={() =>
                                navigate(`/my-meetings/${meeting.id}`)
                              }
                            >
                              <img src={playIcon} alt="play" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                            <button
                              disabled={isNotAllowedToEdit}
                              type="button"
                              onClick={() =>
                                navigate(`/my-meetings/${meeting.id}/edit`)
                              }
                            >
                              <img src={editIcon} alt="edit" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Share</Tooltip>}
                          >
                            <button
                              disabled={isNotAllowedToShare}
                              className={classes.shareButton}
                              type="button"
                              onClick={() =>
                                navigate(
                                  `/my-meetings/${meeting.id}/edit?share=true`
                                )
                              }
                            >
                              <img src={shareIcon} alt="share" />
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className={classes.noMeetings}>
            Oh hey! There are no meetings created for you or by you.
            <br />
            Go to &quot;Create a Meeting&quot; to get started.
          </p>
        )}

        {+data?.[0]?.count > 10 && (
          <div className={classes.paginationContainer}>
            <Pagination
              itemClass={classes.button}
              innerClass={classes.buttonsList}
              activeClass={classes.active}
              disabledClass={classes.disabled}
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={+data?.[0]?.count || 0}
              pageRangeDisplayed={5}
              prevPageText="Prev"
              nextPageText="Next"
              onChange={switchPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
