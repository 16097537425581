/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import backIcon from '../../../assets/images/profile/back.svg';
import WaveForm from '../../MediaContainer/WaveForm';
import videoTimeToHMS from '../../../helpers/videoTimeToHMS';
import { STATIC_URL } from '../../../constants/main';
import classes from './styles.module.scss';

export default function MeetingVideoPlayer({
  currentAudioSectionWithoutBackground,
  currentAudioSectionProgress,
  meeting,
  waveFormContainerRef,
  videoRef,
  isDraggingProgressBar,
  progressBarRef,
  progressBarHandler,
  setIsDraggingProgressBar,
  videoDuration,
  data,
  setPreviousShownQuestions,
  setPreviousShownNote,
  animateProgressBar,
  stopAnimation,
  videoProgress,
  isSectionsVisible,
  setIsSectionsVisible,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleBackClick = useCallback(() => {
    if (pathname.endsWith('/preview')) {
      navigate(pathname.replace('/preview', '/edit'));
    } else {
      navigate('/my-meetings');
    }
  }, []);

  // Reset last shown question if video has ended
  useEffect(() => {
    if (videoRef.current.currentTime === videoRef.current.duration) {
      setPreviousShownQuestions([]);
      setPreviousShownNote({});
    }
  }, [videoRef.current?.currentTime]);

  const playButtonHandler = () => {
    videoRef.current.play();
    animateProgressBar();
  };

  const rewindButtonHandler = () => {
    videoRef.current.currentTime -= 15;
    animateProgressBar();
  };

  const pauseButtonHandler = () => {
    videoRef.current.pause();
    stopAnimation();
  };

  const forwardButtonHandler = () => {
    videoRef.current.currentTime += 30;
    animateProgressBar();
  };

  return (
    <div className={classes.MeetingVideoPlayer}>
      <button
        className={classes.backButton}
        type="button"
        onClick={handleBackClick}
      >
        <img src={backIcon} alt="Back" />
      </button>
      <button
        className={classes.sectionsVisibilityButton}
        type="button"
        onClick={() => setIsSectionsVisible((prevState) => !prevState)}
      >
        {isSectionsVisible ? 'Hide sections' : 'Show sections'}
      </button>
      {currentAudioSectionWithoutBackground && (
        <div className={classes.waveFormContainer} ref={waveFormContainerRef}>
          <WaveForm
            source={`${window.location.protocol}//${
              window.location.host
            }${currentAudioSectionWithoutBackground.section.path.replace(
              /\\/g,
              '/'
            )}`}
            containerRef={waveFormContainerRef}
            fullHeight
            seek={currentAudioSectionProgress}
          />
        </div>
      )}
      <video
        src={STATIC_URL + (meeting ? meeting.videoPath : data?.videoPath)}
        ref={videoRef}
      />
      <div className={classes.progressBarContainer}>
        <div
          className={classNames(
            classes.progressBar,
            isDraggingProgressBar && classes.dragging
          )}
          ref={progressBarRef}
          onMouseDown={() => setIsDraggingProgressBar(true)}
          onClick={progressBarHandler}
        >
          <div
            className={classes.progress}
            style={{
              width: `${videoProgress}%`,
            }}
          />
        </div>
        {videoRef.current && (
          <div className={classes.time}>
            {videoTimeToHMS(videoRef.current.currentTime)} /{' '}
            {videoTimeToHMS(videoDuration)}
          </div>
        )}
      </div>
      <div className={classes.controlsContainer}>
        <div className={classes.controls}>
          <div onClick={rewindButtonHandler}>
            <svg
              width="50"
              height="48"
              viewBox="0 0 50 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m 10.059386,34.0993 c 3.361729,5.1614 9.222495,8.5885 15.903771,8.5885 10.434861,0 18.89502,-8.3437 18.89502,-18.6354 0,-10.2864 -8.460159,-18.6302 -18.89502,-18.6302 -8.131906,0 -15.056732,5.0625 -17.7197276,12.1667 H 11.790567 L 5.8980866,25.8753 0.0056061,17.589 3.0550707,17.5889 C 5.9033719,7.75553 15.078296,0.557617 25.963157,0.557617 c 13.161386,0 23.829221,10.520883 23.829221,23.494783 0,12.9844 -10.667835,23.5052 -23.829221,23.5052 -8.735497,0 -16.3697294,-4.6406 -20.5150704,-11.5573 z"
                fill="white"
              />
              <text x="19" y="30" fill="white">
                15
              </text>
            </svg>
          </div>
          <div className={classes.playButtonContainer}>
            <div className={classes.playButton} onClick={playButtonHandler} />
          </div>
          <div className={classes.pauseButton} onClick={pauseButtonHandler}>
            <div />
            <div />
          </div>
          {/* <div className={classes.stopButton} onClick={stopButtonHandler} /> */}
          <div onClick={forwardButtonHandler}>
            <svg
              width="50"
              height="48"
              viewBox="0 0 50 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.0091 42.6878C13.7435 42.6878 5.42057 34.3441 5.42057 24.0524C5.42057 13.766 13.7435 5.4222 24.0091 5.4222C32.0091 5.4222 38.8216 10.4847 41.4414 17.5889H37.9518L43.7487 25.8753L49.5456 17.5889H46.5456C43.7435 7.75553 34.7174 0.557617 24.0091 0.557617C11.0612 0.557617 0.566406 11.0785 0.566406 24.0524C0.566406 37.0368 11.0612 47.5576 24.0091 47.5576C32.6029 47.5576 40.1133 42.917 44.1914 36.0003L39.6549 34.0993C36.3477 39.2607 30.582 42.6878 24.0091 42.6878Z"
                fill="white"
              />
              <text x="15" y="30" fill="white">
                30
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
