import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import userIcon from '../../assets/images/usericon.webp';
import { STATIC_URL } from '../../constants/main';
import { hasAuthData } from '../../helpers/authStorage';
import { logout } from '../../helpers/globalAuth';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';

export default function Header({ hasUserBlock }) {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isLoggedIn = hasAuthData();

  const userMenuRef = useRef();
  const userPhotoRef = useRef();

  useOnClickOutside(
    userMenuRef,
    () => setIsUserMenuVisible(false),
    userPhotoRef
  );

  const { data, refetch } = useQuery('me', UserService.getMe, {
    enabled: false,
  });

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  return (
    <header className={classes.Header}>
      <h2>Socialmeeting</h2>

      {hasUserBlock && (
        <div className={classes.userBlock}>
          <span className={classes.username}>{data?.name}</span>
          <div className={classes.userMenuContainer}>
            {isUserMenuVisible && (
              <ul className={classes.userMenu} ref={userMenuRef}>
                <li>
                  <button
                    className={classes.profileButton}
                    type="button"
                    onClick={() => {
                      setIsUserMenuVisible(false);
                      navigate('/profile');
                    }}
                  >
                    <div className={classes.iconBackground}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                          fill="#545982"
                        />
                        <path
                          d="M17.08 14.1499C14.29 12.2899 9.73996 12.2899 6.92996 14.1499C5.65996 14.9999 4.95996 16.1499 4.95996 17.3799C4.95996 18.6099 5.65996 19.7499 6.91996 20.5899C8.31996 21.5299 10.16 21.9999 12 21.9999C13.84 21.9999 15.68 21.5299 17.08 20.5899C18.34 19.7399 19.04 18.5999 19.04 17.3599C19.03 16.1299 18.34 14.9899 17.08 14.1499Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                    Profile Settings
                  </button>
                </li>

                <li>
                  <button
                    type="button"
                    onClick={() => {
                      logout(() => {
                        setIsUserMenuVisible(false);
                        queryClient.removeQueries();
                        navigate('/');
                      });
                    }}
                  >
                    <div className={classes.iconBackground}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 12L15 8M19 12L15 16M19 12H9"
                          stroke="#BBBDCD"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 21C9.02944 21 5 16.9706 5 12C5 7.02944 9.02944 3 14 3"
                          stroke="#292D32"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Logout
                  </button>
                </li>
              </ul>
            )}

            {data?.photoPath ? (
              <div
                className={classes.userPhoto}
                ref={userPhotoRef}
                onClick={() => setIsUserMenuVisible((prevState) => !prevState)}
              >
                <img src={STATIC_URL + data.photoPath} alt="" />
              </div>
            ) : (
              <div
                className={classes.userIcon}
                ref={userPhotoRef}
                onClick={() => {
                  if (!isLoggedIn) {
                    return;
                  }

                  setIsUserMenuVisible((prevState) => !prevState);
                }}
              >
                <img src={userIcon} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
}
