import React from 'react';

import classes from './styles.module.scss';

export default function NotePreview({ content }) {
  return (
    <div
      className={classes.NotePreview}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
