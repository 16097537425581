/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import moment from 'moment-timezone';
import classNames from 'classnames';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import RoundedInput from '../../components/Inputs/RoundedInput';
import Checkbox from '../../components/Checkbox';
import Option from '../../components/Option';
import TimePicker from '../../components/TimePicker';
import DatePicker from '../../components/DatePicker';
import InfoIcon from '../../components/InfoIcon';
import { UiContext } from '../../context/UiContext';
import validateEmail from '../../helpers/validateEmail';
import localTimeZone from '../../helpers/localTimeZone';
import MeetingService from '../../services/MeetingService';
import classes from './styles.module.scss';
import FilledButton from '../../components/Buttons/FilledButton';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import Textarea from '../../components/Textarea';

function convertDateByTimezone(date, time, timezone) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedTime = `${time.split(':')[0]}:${time.split(':')[1]}:00.000`;
  const offset = moment.tz(Date.now(), timezone).format('Z');

  return `${formattedDate}T${formattedTime}${offset}`;
}

export default function ShareMeetingModal({
  show,
  handleClose,
  meetingId,
  setIsShareSuccessPopupVisible,
  selectedTimeZone,
  setIsSelectTimeZoneModalVisible,
  setTimeZoneAssignment,
  openCopyLinkModal,
}) {
  const [security, setSecurity] = useState('private');
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [emailsValidationError, setEmailsValidationError] = useState('');
  const [sendDate, setSendDate] = useState(new Date());
  const [expireDate, setExpireDate] = useState(new Date());
  const [sendTime, setSendTime] = useState('');
  const [expireTime, setExpireTime] = useState('');
  const [shouldSendNow, setShouldSendNow] = useState(true);
  const [doesNotExpire, setDoesNotExpire] = useState(true);
  const [minTime, setMinTime] = useState(23);

  const { showShareMeetingSuccessModal } = useContext(UiContext);

  const isInputDisabled = security === 'restricted' || security === 'public';

  const convertedSendDate = useMemo(() => {
    return convertDateByTimezone(
      sendDate,
      sendTime,
      selectedTimeZone.schedule.value
    );
  }, [selectedTimeZone.schedule.value, sendDate, sendTime]);

  const convertedExpireDate = useMemo(() => {
    return convertDateByTimezone(
      expireDate,
      expireTime,
      selectedTimeZone.expiration.value
    );
  }, [expireDate, expireTime, selectedTimeZone.expiration.value]);

  const share = async () => {
    let isValid = true;
    setEmailsValidationError(null);

    let emailsList = [];

    if (!emails) {
      setEmailsValidationError('This field is required');
      isValid = false;
    } else {
      emailsList = emails.split(';').join(',').split(',');
      emailsList = emailsList.map((mail) => mail.trim());
      const areEmailValid = emailsList.every((mail) => validateEmail(mail));
      if (!areEmailValid) {
        setEmailsValidationError('Emails must be valid');
        isValid = false;
      }
    }

    if (isValid) {
      try {
        if (!shouldSendNow) {
          MeetingService.scheduleMeetingShare({
            meetingId,
            security,
            email: emailsList,
            activatesAt: shouldSendNow
              ? null
              : new Date(convertedSendDate).getTime(),
            expiresAt: doesNotExpire
              ? null
              : new Date(convertedExpireDate).getTime(),
            message,
          });
        } else {
          await axios.post(`/meetings/${meetingId}/share`, {
            emails: emailsList,
            access: security,
            message,
            expiresAt: doesNotExpire
              ? null
              : new Date(convertedExpireDate).getTime(),
          });
        }

        setIsShareSuccessPopupVisible(true);
        showShareMeetingSuccessModal();
        handleClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getLink = async () => {
    const link = await MeetingService.shareMeetingLink({
      meetingId,
      security,
      expiresAt: doesNotExpire ? null : new Date(convertedExpireDate).getTime(),
    });

    openCopyLinkModal(link);
  };

  useEffect(() => {
    if (!show) {
      setEmailsValidationError(null);
      setMessage('');
      setEmails('');
      setSecurity('private');
    }
  }, [show]);

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setSendDate(new Date());
    setExpireDate(tomorrow);

    const nextHour = Math.min(new Date().getHours() + 1, 23);

    setMinTime(nextHour);

    const stringHour = nextHour < 10 ? `0${nextHour}:00` : `${nextHour}:00`;

    setSendTime(stringHour);
    setExpireTime(stringHour);

    setShouldSendNow(true);
    setDoesNotExpire(true);
  }, [show]);

  useEffect(() => {
    if (isInputDisabled) {
      setShouldSendNow(true);
    }
  }, [isInputDisabled]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ShareMeetingModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <header>
        <h1>Share your meeting!</h1>
      </header>
      <div className={classes.form}>
        <div className={classNames(classes.row, classes.inviteRow)}>
          <div
            className={classNames(classes.inviteUsersContainer, classes.col)}
          >
            <div className={classes.title}>
              <h2>Invite Viewers</h2>
              <hr />
            </div>
            <RoundedInput
              type="text"
              placeholder="Add email address"
              value={isInputDisabled ? '-' : emails}
              onChange={(event) => setEmails(event.target.value)}
              disabled={isInputDisabled}
            />
            {emailsValidationError && (
              <div className={classes.error}>{emailsValidationError}</div>
            )}
            {/* <span className={classes.note} style={{ visibility: 'hidden' }}>
              Note: Use a semicolon (;) after each email address when adding
              multiple emails
            </span> */}
          </div>
          <div
            className={classNames(
              classes.securityOptionsContainer,
              classes.col
            )}
          >
            <div className={classes.title}>
              <h2>Select Security</h2>
              <hr />
            </div>
            <Option
              isChecked={security === 'private'}
              onClick={() => {
                setSecurity('private');
              }}
            >
              Send privately
              <div>
                <InfoIcon
                  text="Send meeting directly through SocialMeeting - users must authenticate email to view"
                  width={350}
                />
              </div>
            </Option>

            <Option
              isChecked={security === 'restricted'}
              onClick={() => {
                setSecurity('restricted');
              }}
            >
              Get restricted link
              <div>
                <InfoIcon
                  text="Generates link and passcode. Best for sending to large audiences"
                  width={350}
                />
              </div>
            </Option>

            <Option
              isChecked={security === 'public'}
              onClick={() => {
                setSecurity('public');
              }}
            >
              Get public link
              <div>
                <InfoIcon
                  text="Anyone with a link can view your meeting"
                  width={350}
                />
              </div>
            </Option>
          </div>
        </div>

        <div className={classNames(classes.row, classes.dates)}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h2>Schedule Send</h2>
              <hr />
            </div>
            <div className={classes.inputs}>
              <DatePicker
                disabled={shouldSendNow}
                date={sendDate}
                setDate={setSendDate}
              />
              <TimePicker
                disabled={shouldSendNow}
                time={sendTime}
                setTime={setSendTime}
                minTime={minTime}
              />
            </div>
            <div className={classes.checkboxContainer}>
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  isChecked={shouldSendNow}
                  label="Send now"
                  onClick={() => setShouldSendNow((prevState) => !prevState)}
                  isDisabled={isInputDisabled}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsSelectTimeZoneModalVisible(true);
                  setTimeZoneAssignment('schedule');
                }}
                disabled={isInputDisabled}
              >
                {selectedTimeZone.schedule.value !== localTimeZone
                  ? selectedTimeZone.schedule.label
                  : 'Select timezone'}
              </button>
            </div>
          </div>
          <div className={classes.col}>
            <div className={classes.title}>
              <h2>
                Meeting Expiration
                <div className={classes.infoIconContainer}>
                  <InfoIcon
                    text="Reminders to view your meeting will automatically sent to viewers"
                    width={285}
                  />
                </div>
              </h2>
              <hr />
            </div>
            <div className={classes.inputs}>
              <DatePicker
                disabled={doesNotExpire}
                date={expireDate}
                setDate={setExpireDate}
              />
              <TimePicker
                disabled={doesNotExpire}
                time={expireTime}
                setTime={setExpireTime}
                minTime={
                  moment(expireDate).isSame(sendDate, 'day') ? minTime : '0'
                }
              />
            </div>
            <div className={classes.checkboxContainer}>
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  isChecked={doesNotExpire}
                  label="My meeting does not expire"
                  onClick={() => setDoesNotExpire((prevState) => !prevState)}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsSelectTimeZoneModalVisible(true);
                  setTimeZoneAssignment('expiration');
                }}
              >
                {selectedTimeZone.expiration.value !== localTimeZone
                  ? selectedTimeZone.expiration.label
                  : 'Select timezone'}
              </button>
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h2>Add Message</h2>
              <hr />
            </div>
            <div className={classes.messageContainer}>
              <Textarea
                placeholder="Add message..."
                value={isInputDisabled ? '-' : message}
                onChange={(event) => setMessage(event.target.value)}
                disabled={isInputDisabled}
              />
              {!isInputDisabled && (
                <EmojiPickerWithButton
                  setCommentText={setMessage}
                  buttonBottom="24px"
                  buttonRight="24px"
                  pickerTop="52px"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <footer>
        {/* <div
          // style={{ visibility: security ? 'visible' : 'hidden' }}
          style={{ visibility: 'hidden' }}
          className={classNames(
            classes.info,
            security === 'private' && classes.private
          )}
        >
          Attention:{' '}
          {security === 'public' ? (
            <span>
              Anyone with a link can view <br /> your meeting
            </span>
          ) : (
            <span>
              Private meetings will require user email authentication to view
              meeting
            </span>
          )}
        </div> */}
        {security === 'private' ? (
          <FilledButton onClick={share}>Send</FilledButton>
        ) : (
          <FilledButton onClick={getLink}>Copy Link</FilledButton>
        )}
      </footer>
    </Modal>
  );
}
