/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function RoundedInput({
  value,
  label,
  errors,
  isTouched,
  ...props
}) {
  return (
    <div className={classes.RoundedInput}>
      <input
        className={classNames(
          value && classes.active,
          errors && isTouched && classes.error
        )}
        value={value}
        {...props}
      />
      <label>{label}</label>
    </div>
  );
}
