import React from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import FilledButton from '../../components/Buttons/FilledButton';

import classes from './styles.module.scss';

export default function ShareMeetingSuccessModal({ show, handleClose }) {
  const navigate = useNavigate();

  return (
    <Modal
      onHide={handleClose}
      show={show}
      centered
      contentClassName={classes.ShareMeetingSuccessModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.content}>
        <i className={classes.closeIcon} onClick={handleClose}>
          <img src={closeIcon} alt="close" />
        </i>
        <p>Sweet! Your meeting has been shared.</p>
        <p>What do you want to do next?</p>
        <div className={classes.buttons}>
          <FilledButton
            width={240}
            onClick={() => {
              handleClose();
              navigate('/create-meeting');
            }}
          >
            Create a Meeting
          </FilledButton>
          <FilledButton
            width={240}
            onClick={() => {
              handleClose();
              navigate('/my-meetings');
            }}
          >
            Go to My Meetings
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
}
