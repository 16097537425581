/* eslint-disable react/button-has-type */
import React from 'react';

import classes from './styles.module.scss';

export default function FilledButton({
  onClick,
  children,
  type = 'button',
  isDisabled,
}) {
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={classes.FilledButton}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
