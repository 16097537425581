import React from 'react';

import socketio from 'socket.io-client';

import { WS_URL } from '../constants/main';
import { getAuthData } from '../helpers/authStorage';

const { accessToken } = getAuthData();

export const socket = socketio.connect(
  WS_URL ||
    `${document.location.protocol === 'http' ? 'ws' : 'wss'}://${
      document.location.hostname
    }`,
  {
    auth: {
      token: accessToken,
    },
  }
);

export const SocketContext = React.createContext();
