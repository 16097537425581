import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import Dropdown from './Dropdown';
import Checkbox from '../../components/Checkbox';
import Options from './Options';

import classes from './styles.module.scss';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

export default function CreateQuestionModal({
  show,
  setQuestion,
  fetchedQuestionData,
  updateSection,
  setIsQuestionFormVisible,
  setFetchedQuestionData,
  hidePlaceholder,
}) {
  const [isAnswerRequired, setIsAnswerRequired] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [questionOption, setQuestionOption] = useState('Single Text Field');
  const [options, setOptions] = useState([]);

  const changeQuestionText = (event) => {
    setQuestionText(event.target.value);
  };

  const submitQuestion = () => {
    if (!questionText.trim()) {
      return;
    }

    const answerOptions =
      questionOption !== 'Single Text Field' && options.length
        ? {
            type: questionOption,
            options: options.map((option) => option.value),
          }
        : null;

    if (sectionId) {
      updateSection({
        sectionId,
        question: {
          isAnswerRequired,
          text: questionText,
          options: answerOptions,
        },
      });
      setIsQuestionFormVisible(false);

      setTimeout(() => {
        setFetchedQuestionData(null);
      }, 200);
    } else {
      setQuestion({
        isAnswerRequired,
        text: questionText,
        options: answerOptions,
      });
    }
  };

  const cancelAddingQuestion = () => {
    setIsQuestionFormVisible(false);
    hidePlaceholder();
  };

  useEffect(() => {
    if (fetchedQuestionData) {
      setIsAnswerRequired(fetchedQuestionData.isAnswerRequired);
      setQuestionText(fetchedQuestionData.text);
      setSectionId(fetchedQuestionData.sectionId);

      let questionType = 'Single Text Field';

      if (fetchedQuestionData.options) {
        questionType = fetchedQuestionData.options.type;

        setOptions(
          fetchedQuestionData.options.options.map((option) => ({
            key: Math.random(),
            value: option,
          }))
        );
      }

      setQuestionOption(questionType);
    } else {
      setSectionId('');
      setIsAnswerRequired(false);
      setQuestionText('');
      setQuestionOption('Single Text Field');
    }
  }, [fetchedQuestionData]);

  /*   useEffect(() => {
    setFetchedQuestionData(null);
  }, [setFetchedQuestionData]); */

  useEffect(() => {
    if (!fetchedQuestionData || !fetchedQuestionData.options) {
      setOptions([
        {
          value: 'Add option',
          key: Math.random(),
        },
        {
          value: 'Add option',
          key: Math.random(),
        },
      ]);
    }
  }, [questionOption, fetchedQuestionData]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.CreateQuestionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={cancelAddingQuestion}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4858 15.502L12.9836 9.99986L18.4858 4.49768C19.3095 3.67392 19.3095 2.33835 18.4858 1.51458C17.662 0.69081 16.3264 0.69081 15.5027 1.51458L10.0005 7.01675L4.49831 1.51458C3.67455 0.69081 2.33898 0.69081 1.51521 1.51458C0.691438 2.33835 0.691439 3.67392 1.51521 4.49768L7.01738 9.99986L1.51521 15.502C0.691439 16.3258 0.691438 17.6614 1.51521 18.4851C2.33898 19.3089 3.67455 19.3089 4.49831 18.4851L10.0005 12.983L15.5027 18.4851C16.3264 19.3089 17.662 19.3089 18.4858 18.4851C19.3095 17.6614 19.3095 16.3258 18.4858 15.502Z"
            fill="black"
          />
        </svg>
      </i>
      <div className={classes.content}>
        <h3>Insert a question</h3>
        <div className={classes.form}>
          <Dropdown
            questionOption={questionOption}
            setQuestionOption={setQuestionOption}
          />
          <div className={classes.questionContainer}>
            <textarea
              placeholder="Type your question"
              value={questionText}
              onChange={changeQuestionText}
            />
            <EmojiPickerWithButton
              setCommentText={setQuestionText}
              buttonBottom="12px"
              buttonRight="24px"
              pickerTop="52px"
            />
          </div>
          {questionOption !== 'Single Text Field' && (
            <div className={classes.optionsContainer}>
              <Options
                options={options}
                setOptions={setOptions}
                questionOption={questionOption}
              />
            </div>
          )}
        </div>
        <footer className={classes.footer}>
          <Checkbox
            isChecked={isAnswerRequired}
            onClick={() => setIsAnswerRequired((prevState) => !prevState)}
            label="Responses to this question are required"
            labelStyle={{
              fontSize: '24px',
              linHeight: '100%',
              color: 'rgba(0, 0, 0, 0.8)',
            }}
          />
          <ConfirmButton
            onClick={submitQuestion}
            isDisabled={!questionText.trim()}
          />
        </footer>
      </div>
    </Modal>
  );
}
