import React from 'react';

import { STATIC_URL } from '../../../constants/main';
import usericon from '../../../assets/images/usericon.webp';
import classes from './styles.module.scss';

export default function Answer({ answer }) {
  return (
    <div className={classes.Answer}>
      <div className={classes.userpic}>
        <img
          alt="User"
          src={
            answer.User?.photoPath
              ? STATIC_URL + answer.User?.photoPath
              : usericon
          }
        />
      </div>
      <div className={classes.text}>
        <h1>{answer.User?.name}</h1>
        <h2>{answer.User?.title}</h2>
        <p>{answer.text}</p>
      </div>
    </div>
  );
}
