/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo, useState } from 'react';

import axios from 'axios';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import SplitTemplate from '../../components/SplitTemplate';
import UnderlinedInput from '../../components/Inputs/UnderlinedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'The password should have at minimum length of 8'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

export default function ChangePasswordPage() {
  const [currentView, setCurrentView] = useState('change');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();

  const { notifySuccess } = useContext(UiContext);

  const changePassword = async ({ password }) => {
    try {
      const response = await axios.post('/change-password', {
        token,
        password,
      });

      notifySuccess(response.data.message);
      navigate('/sign-in');
    } catch (error) {
      console.log(error);
      setCurrentView('expired');
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: changePassword,
  });

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  return (
    <div className={classes.ChangePasswordPage}>
      <SplitTemplate>
        {currentView === 'change' && (
          <>
            <h1>Create new password</h1>
            <form onSubmit={formik.handleSubmit}>
              <UnderlinedInput
                label={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : 'New password'
                }
                value={formik.values.password}
                errors={formik.errors.password}
                isTouched={formik.touched.password}
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isPassword
                doPasswordsMatch={
                  formik.errors.confirmPassword !== 'Passwords do not match'
                }
              />

              <UnderlinedInput
                label={
                  formik.errors.confirmPassword === 'This field is required' &&
                  formik.touched.confirmPassword
                    ? 'This field is required'
                    : 'Repeat password'
                }
                value={formik.values.confirmPassword}
                errors={
                  formik.errors.confirmPassword === 'This field is required'
                }
                isTouched={formik.touched.confirmPassword}
                type="password"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <ConfirmButton isDisabled={isSubmitDisabled} />
            </form>
          </>
        )}

        {currentView === 'expired' && (
          <>
            <span className={classes.resetPassword}>
              <Link to="/forgot-password">Reset password</Link>
            </span>
            <h1>The link has expired</h1>
            <p>
              Seems the reset password link has expired. No worries though, just
              try to reset the password again.
            </p>
          </>
        )}
      </SplitTemplate>
    </div>
  );
}
