import React, { useContext, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { SocketContext } from '../../context';
import { UiContext } from '../../context/UiContext';
import { login } from '../../helpers/globalAuth';
import SplitTemplate from '../../components/SplitTemplate';
import UnderlinedInput from '../../components/Inputs/UnderlinedInput';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup.string().required('This field is required'),
});

export default function SignInPage() {
  const navigate = useNavigate();
  const { notifyError, redirectAfterLogin, setRedirectAfterLogin } =
    useContext(UiContext);
  const { socket } = useContext(SocketContext);

  const signIn = async (values) => {
    try {
      await login(values.email, values.password, socket);

      navigate(redirectAfterLogin);

      if (redirectAfterLogin) {
        setRedirectAfterLogin('');
      }
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: signIn,
  });

  useEffect(() => {
    if (!redirectAfterLogin.length) {
      setRedirectAfterLogin('/my-meetings');
    }
  }, []);

  const isRedirectToMeeting =
    /\/my-meetings\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/.test(
      redirectAfterLogin
    );

  const hasError = useMemo(
    () => Object.values(formik.errors).some((error) => error),
    [formik.errors]
  );
  const hasEmptyValue = useMemo(
    () => Object.values(formik.values).some((value) => !value.length),
    [formik.values]
  );

  const isSubmitDisabled = hasError || hasEmptyValue;

  return (
    <div className={classes.SignInPage}>
      <SplitTemplate>
        <span className={classes.signUp}>
          <Link to="/sign-up">Sign up</Link>
        </span>
        <h1>
          {isRedirectToMeeting ? 'Sign in to view your meeting' : 'Sign in'}
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <UnderlinedInput
            label={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : 'Your email'
            }
            value={formik.values.email}
            errors={formik.errors.email}
            isTouched={formik.touched.email}
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <UnderlinedInput
            label={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : 'Your password'
            }
            value={formik.values.password}
            errors={formik.errors.password}
            isTouched={formik.touched.password}
            type="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div className={classes.row}>
            <span className={classes.forgotPassword}>
              Forgot your password? <br />
              <Link to="/forgot-password">Click here</Link> to recover it
            </span>
            <ConfirmButton isDisabled={isSubmitDisabled} />
          </div>
        </form>
      </SplitTemplate>
    </div>
  );
}
