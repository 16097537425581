/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classes from './styles.module.scss';

export default function Switch({ isChecked, onClick }) {
  return (
    <label className={classes.Switch}>
      <input type="checkbox" checked={isChecked} onChange={onClick} />
      <span className={classes.slider} />
    </label>
  );
}
