/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';

import remove from '../../../assets/images/create-question/remove.svg';
import multipleChoice from '../../../assets/images/create-question/multipleChoice.svg';
import singleChoice from '../../../assets/images/create-question/singleChoice.svg';
import classes from './styles.module.scss';

const checkBox = <img src={multipleChoice} alt="checkbox" />;

const radioButton = <img src={singleChoice} alt="radio button" />;

export default function Options({ options, setOptions, questionOption }) {
  const changeOption = (key, value) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.key === key) {
          return { key, value };
        }
        return option;
      })
    );
  };

  const addOption = () => {
    setOptions((prevOptions) => [
      ...prevOptions,
      { key: Math.random(), value: 'Add option' },
    ]);
  };

  const deleteOption = (key) => {
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.key !== key)
    );
  };

  useEffect(() => {
    const inputs = document.querySelectorAll(`.${classes.optionInput}`);

    if (inputs && inputs.length) {
      inputs[inputs.length - 1].focus();
    }
  }, [options.length]);

  return (
    <div className={classes.Options}>
      <ul
        className={classes.list}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            addOption();
          }
        }}
      >
        {options.map((option) => (
          <li key={option.key}>
            <div className={classes.optionTitleContainer}>
              {questionOption === 'Multiple Choice' ? checkBox : radioButton}
              <input
                className={classes.optionInput}
                type="text"
                value={option.value}
                onChange={(event) =>
                  changeOption(option.key, event.target.value)
                }
              />
            </div>
            <i
              className={classes.deleteIcon}
              onClick={() => deleteOption(option.key)}
            >
              <img src={remove} alt="delete" />
            </i>
          </li>
        ))}
      </ul>
      <div className={classes.info}>Press [Enter] to add more options</div>
    </div>
  );
}
