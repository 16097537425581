/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import classNames from 'classnames';

import WaveForm from './WaveForm';
import { STATIC_URL } from '../../constants/main';
import PlayerButtons from './PlayerButtons';
// import AssignSectionForm from './AssignSectionForm';
import ConfirmSectionRemoval from '../ConfirmSectionRemoval';
// import QuestionForm from './QuestionForm';
import classes from './styles.module.scss';
import BigPopup from './BigPopup';
import NoTitleWarningPopup from './NoTitleWarningPopup';
import videoTimeToHMS from '../../helpers/videoTimeToHMS';
import NotePreview from './NotePreview';
import AlertModal from '../../modals/AlertModal';
import AssignSectionModal from '../../modals/AssignSectionModal';
import CreateQuestionModal from '../../modals/CreateQuestionModal';

let timer;
let animationId;

const MediaContainer = React.memo(
  ({
    setIsSaveSectionPopupVisible,
    isSaveSectionPopupVisible,
    // saveSectionSuccessMessage,
    isConfirmSectionRemovalVisible,
    isCameraEnabled,
    isScreenEnabled,
    setIsConfirmSectionRemovalVisible,
    deleteSection,
    videoPlayerSource,
    startCameraMediaRecorder,
    // startScreenMediaRecorder,
    videoSectionPlayerRef,
    videoPlayerRef,
    mediaRecorderRef,
    isRecording,
    setIsRecording,
    hasFetchedMeeting,
    hidePlaceholder,
    isQuestionFormVisible,
    setIsQuestionFormVisible,
    setQuestion,
    fetchedQuestionData,
    setFetchedQuestionData,
    updateSection,
    isAssignSectionFormVisible,
    meetingId,
    hideAssignSectionForm,
    showPlaceholder,
    createMeeting,
    meetingTitle,
    cameraHasStopped,
    setCameraHasStopped,
    // screenHasStopped,
    // setScreenHasStopped,
    stopVideoPlayer,
    isPublishMeetingPopupVisible,
    isNoTitleWarningVisible,
    setIsNoTitleWarningVisible,
    refetchSections,
    isSectionsCompletePopupVisible,
    setMeetingId,
    isAudioEnabled,
    startAudioMediaRecorder,
    isScreenRecordReminderVisible,
    setIsScreenRecordReminderVisible,
    startScreen,
    isSafari,
    isShareSuccessPopupVisible,
    setIsShareSuccessPopupVisible,
    stopAllTracks,
    isPreviewPopupVisible,
    videoPlayerContainerRef,
    audioImageFileBackground,
    isNoContentWarningPopupVisible,
    setAudioImage,
    currentSection,
  }) => {
    const [isCameraRecordingPaused, setIsCameraRecordingPaused] =
      useState(false);
    const [timerValue, setTimerValue] = useState(3);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
    const [isAssignSectionErrorPopupVisible, setIsAssignSectionPopupVisible] =
      useState(false);
    const [assignSectionErrorMessage, setAssignSectionErrorMessage] =
      useState('');
    const [recordingTime, setRecordingTime] = useState(0);
    const [videoProgress, setVideoProgress] = useState(0);

    const mediaContainerRef = useRef();

    useEffect(() => {
      const videoRefCurrent = videoSectionPlayerRef?.current;

      const handleLoadMetadata = () => {
        if (videoRefCurrent.duration === Infinity) {
          videoRefCurrent.currentTime = 1e101;
          videoRefCurrent.ontimeupdate = () => {
            videoRefCurrent.ontimeupdate = () => {};
            videoRefCurrent.currentTime = 0;
          };
        }
      };

      if (videoRefCurrent) {
        videoRefCurrent.addEventListener('loadedmetadata', handleLoadMetadata);
      }

      return () => {
        if (videoRefCurrent) {
          videoRefCurrent.removeEventListener(
            'loadedmetadata',
            handleLoadMetadata
          );
        }
      };
    });

    const animateProgressBar = useCallback(() => {
      const newLocal =
        (100 / videoSectionPlayerRef.current?.duration) *
        videoSectionPlayerRef.current?.currentTime;
      setVideoProgress(newLocal);

      animationId = requestAnimationFrame(animateProgressBar);
    }, []);

    const stopAnimation = () => {
      cancelAnimationFrame(animationId);
    };

    useEffect(() => {
      return () => {
        stopAnimation();
      };
    }, [animateProgressBar, videoSectionPlayerRef]);

    const pauseVideoPlayer = () => {
      if (videoPlayerSource === 'stream' || videoPlayerSource === 'audio') {
        mediaRecorderRef.current.pause();
        setIsCameraRecordingPaused(true);
      } else {
        videoSectionPlayerRef.current.pause();
      }
      /*   setIsPaused(true);
      setIsPlaying(false); */
    };

    const startVideoPlayer = () => {
      if (videoPlayerSource === 'stream' || videoPlayerSource === 'audio') {
        if (isCameraRecordingPaused) {
          mediaRecorderRef.current.resume();
        }

        setIsCameraRecordingPaused(false);
      } else {
        videoSectionPlayerRef.current.play();
        animateProgressBar();
      }

      /*  setIsPlaying(true);
      setIsPaused(false); */
    };

    const startVideoRecording = () => {
      setIsRecording(true);
      mediaRecorderRef.current.start();
    };

    useEffect(() => {
      if (isRecording) {
        timer = setInterval(() => {
          setRecordingTime((prevRecordingTime) => prevRecordingTime + 1);
        }, 1000);
      } else {
        clearInterval(timer);
        setRecordingTime(0);
      }
    }, [isRecording]);

    const startTimer = () => {
      if (
        isTimerRunning ||
        isRecording ||
        !mediaRecorderRef.current ||
        (!isScreenEnabled && !isCameraEnabled && !isAudioEnabled)
      ) {
        return;
      }

      if (isCameraEnabled && cameraHasStopped) {
        startCameraMediaRecorder();
        // }
        // else if (isScreenEnabled && screenHasStopped) {
        //   startScreenMediaRecorder(() => {
        //     setIsTimerRunning(true);

        //     setTimeout(() => {
        //       setTimerValue(2);
        //     }, 1000);

        //     setTimeout(() => {
        //       setTimerValue(1);
        //     }, 2000);

        //     setTimeout(() => {
        //       setIsRecording(true);
        //       mediaRecorderRef.current.start();
        //       setIsTimerRunning(false);
        //       setTimerValue(3);
        //     }, 3000);
        //   });
        //   return;
      } else if (isAudioEnabled) {
        startAudioMediaRecorder();
      }

      setIsTimerRunning(true);

      setTimeout(() => {
        setTimerValue(2);
      }, 1000);

      setTimeout(() => {
        setTimerValue(1);
      }, 2000);

      setTimeout(() => {
        startVideoRecording();
        setIsTimerRunning(false);
        setTimerValue(3);
      }, 3000);
    };

    useEffect(() => {
      return () => {
        stopAllTracks();
      };
    }, [stopAllTracks]);

    useEffect(() => {
      if (!isCameraEnabled) {
        setCameraHasStopped(false);
      }
    }, [isCameraEnabled, setCameraHasStopped]);

    // useEffect(() => {
    //   if (!isScreenEnabled) {
    //     setScreenHasStopped(false);
    //   }
    // }, [isScreenEnabled, setScreenHasStopped]);

    useEffect(() => {
      if (isSaveSectionPopupVisible) {
        setTimeout(() => {
          setIsSaveSectionPopupVisible(false);
        }, 3000);
      }
    }, [isSaveSectionPopupVisible, setIsSaveSectionPopupVisible]);

    useEffect(() => {
      if (isScreenEnabled && isRecording) {
        const containerHeight = videoPlayerContainerRef.current.offsetHeight;
        const videoHeight = videoPlayerRef.current.offsetHeight;
        const screenRecordingMessageBottomPosition =
          containerHeight - (videoHeight + 30);

        videoPlayerContainerRef.current.style.setProperty(
          '--bottom',
          `${screenRecordingMessageBottomPosition - 1}px`
        );
      }
    }, [isScreenEnabled, isRecording, videoPlayerRef, videoPlayerContainerRef]);

    useEffect(() => {
      const pauseWaveForm = () => {
        setIsPlaying(false);
        setIsPaused(true);
      };

      const playWaveForm = () => {
        setIsPlaying(true);
        setIsPaused(false);
        setIsEnded(false);
      };

      const stopWaveForm = () => {
        setIsPlaying(false);
        setIsEnded(true);
      };

      videoSectionPlayerRef.current?.addEventListener('play', playWaveForm);
      videoSectionPlayerRef.current?.addEventListener('pause', pauseWaveForm);
      videoSectionPlayerRef.current?.addEventListener('ended', stopWaveForm);

      return () => {
        videoSectionPlayerRef.current?.removeEventListener(
          'play',
          playWaveForm
        );
        videoSectionPlayerRef.current?.removeEventListener(
          'pause',
          pauseWaveForm
        );
        videoSectionPlayerRef.current?.removeEventListener(
          'ended',
          stopWaveForm
        );
        setIsPlaying(false);
        setIsPaused(false);
        setIsEnded(true);
      };
    }, [videoSectionPlayerRef, videoPlayerSource]);

    let videoPlayerToShow;

    const isPlayerEnabled =
      isCameraEnabled || isScreenEnabled || isAudioEnabled || hasFetchedMeeting;

    if (videoPlayerSource === 'stream') {
      videoPlayerToShow = <video key={1} ref={videoPlayerRef} muted />;
    } else if (videoPlayerSource === 'audio') {
      const audioComponent = audioImageFileBackground ? (
        <div
          className={classes.audioRecorder}
          style={{
            visibility: isAudioEnabled ? 'visible' : 'hidden',
            backgroundImage: audioImageFileBackground,
          }}
        />
      ) : (
        <WaveForm
          containerRef={mediaContainerRef}
          isRecording={isRecording}
          isAudioEnabled={isAudioEnabled}
        />
      );

      videoPlayerToShow = isPlayerEnabled ? audioComponent : null;
    } else if (videoPlayerSource?.type === 'note') {
      videoPlayerToShow = <NotePreview content={videoPlayerSource.text} />;
    } else {
      videoPlayerToShow = (
        <>
          {videoPlayerSource?.type === 'audio' && (
            <>
              {currentSection?.audioImagePath ? (
                <div
                  className={classes.audioRecorder}
                  style={{
                    backgroundImage: `url(${STATIC_URL}${currentSection.audioImagePath})`,
                  }}
                />
              ) : (
                <WaveForm
                  containerRef={mediaContainerRef}
                  isPlaying={isPlaying}
                  isPaused={isPaused}
                  isEnded={isEnded}
                  source={
                    videoPlayerSource
                      ? STATIC_URL + videoPlayerSource?.path
                      : null
                  }
                />
              )}
            </>
          )}
          <video
            className={classNames(
              videoPlayerSource?.type === 'audio' && classes.absolute
            )}
            key={2}
            ref={videoSectionPlayerRef}
            src={
              videoPlayerSource ? STATIC_URL + videoPlayerSource?.path : null
            }
          />
        </>
      );
    }

    return (
      <div
        ref={mediaContainerRef}
        className={classNames(classes.MediaContainer, {
          [classes.notSafari]: !isSafari,
        })}
      >
        <div
          ref={videoPlayerContainerRef}
          className={classNames(classes.player, {
            [classes.audio]:
              videoPlayerSource?.type === 'audio' || isAudioEnabled,
            [classes.screenRecording]: isScreenEnabled && isRecording,
          })}
        >
          {isRecording && !isCameraRecordingPaused && (
            <div className={classes.recordIcon}>
              <div className={classes.red} />
              REC
            </div>
          )}
          {isNoTitleWarningVisible && (
            <NoTitleWarningPopup
              onClose={() => setIsNoTitleWarningVisible(false)}
            />
          )}
          {isPublishMeetingPopupVisible && (
            <BigPopup
              title="Your file is being processed"
              message="This will only take a few minutes. We'll also send an email when your file is ready to share."
            />
          )}
          {isSectionsCompletePopupVisible && (
            <BigPopup
              title="AWESOME!"
              message="Your section(s) have been submitted to the meeting owner"
            />
          )}
          {isShareSuccessPopupVisible && (
            <BigPopup
              timeToClose={3000}
              closeIcon
              message={<b>Your meeting has been sent!</b>}
              onClose={() => setIsShareSuccessPopupVisible(false)}
            />
          )}
          {isPreviewPopupVisible && (
            <BigPopup
              title="Please wait"
              message="Preview video is being prepared"
            />
          )}
          {isNoContentWarningPopupVisible && (
            <BigPopup
              title="Hey!"
              message="You have to add content to your meeting in order to publish"
            />
          )}
          {isConfirmSectionRemovalVisible && (
            <div className={classes.confirmSectionRemoval}>
              <div className={classes.backdrop} />
              <ConfirmSectionRemoval
                onHide={() => setIsConfirmSectionRemovalVisible(false)}
                onConfirm={deleteSection}
              />
            </div>
          )}
          {isAssignSectionErrorPopupVisible && (
            <BigPopup
              title="Oops, something happened"
              message={assignSectionErrorMessage}
              closeIcon
              onClose={() => setIsAssignSectionPopupVisible(false)}
              timeToClose={3000}
            />
          )}
          {isTimerRunning && (
            <div className={classes.timerOverlay} onClick={startTimer}>
              <div className={classes.timer}>
                <span>{timerValue}</span>
              </div>
            </div>
          )}
          {videoPlayerToShow}
          {(isPlaying || isPaused) && videoSectionPlayerRef.current && (
            <div className={classes.progressBar}>
              <div
                className={classes.progress}
                style={{
                  width: `${videoProgress}%`,
                }}
              />
              <div className={classes.time}>
                {videoTimeToHMS(videoSectionPlayerRef.current?.currentTime)} /{' '}
                {videoTimeToHMS(videoSectionPlayerRef.current?.duration)}
              </div>
            </div>
          )}
          {isRecording && (
            <div className={classes.recordingTime}>
              {videoTimeToHMS(recordingTime)}
            </div>
          )}
          {isPlayerEnabled && videoPlayerSource?.type !== 'note' && (
            <PlayerButtons
              isAudio={videoPlayerSource === 'audio'}
              stopVideoPlayer={stopVideoPlayer}
              pauseVideoPlayer={pauseVideoPlayer}
              startVideoPlayer={startVideoPlayer}
              startTimer={startTimer}
              isRecording={isRecording}
              createSectionMode={
                isCameraEnabled || isAudioEnabled || isScreenEnabled
              }
              setAudioImage={setAudioImage}
            />
          )}
        </div>
        <AlertModal
          show={isScreenRecordReminderVisible}
          title="You're getting ready to share your screen"
          message="Recording will start in 3 seconds. Come back to SocialMeeting to stop
          recording."
          label="Select an option and start recording"
          handleClose={() => {
            setIsScreenRecordReminderVisible(false);
            hidePlaceholder();
          }}
          onConfirm={() => {
            setIsScreenRecordReminderVisible(false);

            startScreen(() => {
              setIsTimerRunning(true);
              setTimeout(() => {
                setTimerValue(2);
              }, 1000);

              setTimeout(() => {
                setTimerValue(1);
              }, 2000);

              setTimeout(() => {
                setIsRecording(true);
                mediaRecorderRef.current.start();
                setIsTimerRunning(false);
                setTimerValue(3);
              }, 3000);
            });
          }}
        />
        <AssignSectionModal
          show={isAssignSectionFormVisible}
          handleClose={hideAssignSectionForm}
          meetingId={meetingId}
          showPlaceholder={showPlaceholder}
          createMeeting={createMeeting}
          meetingTitle={meetingTitle}
          refetchSections={refetchSections}
          setMeetingId={setMeetingId}
          setIsAssignSectionPopupVisible={setIsAssignSectionPopupVisible}
          setAssignSectionErrorMessage={setAssignSectionErrorMessage}
        />
        <CreateQuestionModal
          show={isQuestionFormVisible}
          setQuestion={setQuestion}
          fetchedQuestionData={fetchedQuestionData}
          setFetchedQuestionData={setFetchedQuestionData}
          updateSection={updateSection}
          setIsQuestionFormVisible={setIsQuestionFormVisible}
          hidePlaceholder={hidePlaceholder}
        />
      </div>
    );
  }
);

export default MediaContainer;
