/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { SocketContext, socket } from '../context';
import UiContextProvider from '../context/UiContext';

import PrivateRoute from '../containers/PrivateRoute';
import SignUpPage from '../pages/SignUpPage';
import SignInPage from '../pages/SignInPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ProfilePage from '../pages/ProfilePage';
import CreateOrUpdateMeetingPage from '../pages/CreateOrUpdateMeetingPage';
import MeetingsPage from '../pages/MeetingsPage';
import MeetingPage from '../pages/MeetingPage';
import SharedMeetingPage from '../pages/SharedMeetingPage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import NotificationsPage from '../pages/NotificationsPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const RoutesComponent = () => {
  /*   const [redirectAfterLogin, setRedirectAfterLogin] = useState('');

  const notifyError = useCallback(
    (message, options) => toast.error(message, options),
    []
  );
  const notifySuccess = useCallback(
    (message, options) => toast.success(message, options),
    []
  ); */

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3230088, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UiContextProvider>
          <SocketContext.Provider value={{ socket }}>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/my-meetings" replace />}
              />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-meeting"
                element={
                  <PrivateRoute>
                    <CreateOrUpdateMeetingPage key={1} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-meetings"
                element={
                  <PrivateRoute>
                    <MeetingsPage />
                  </PrivateRoute>
                }
              />
              <Route path="/my-meetings/:id" element={<MeetingPage />} />
              <Route
                path="/my-meetings/:id/edit"
                element={
                  <PrivateRoute>
                    <CreateOrUpdateMeetingPage key={2} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-meetings/:id/preview"
                element={
                  <PrivateRoute>
                    <MeetingPage preview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <NotificationsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shared-meeting/:id"
                element={<SharedMeetingPage />}
              />
            </Routes>
          </SocketContext.Provider>
        </UiContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default RoutesComponent;
