/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import { options } from './options';
import Select from '../Select';
import classes from './styles.module.scss';

function convertTo12HourFormat(time) {
  let hours = +time.slice(0, 2);
  const minutes = time.slice(3);
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}

export default function TimePicker({ time, setTime, disabled, minTime }) {
  const handleSelectTime = useCallback(
    (option, index) => {
      if (index >= minTime) {
        setTime(option.value);
      }
    },
    [minTime, setTime]
  );

  let displayedValue = 'Please select';

  if (time) {
    displayedValue = convertTo12HourFormat(time);
  }

  if (disabled) {
    displayedValue = '-';
  }

  return (
    <div className={classes.TimePicker}>
      <Select
        disabled={disabled}
        displayedValue={displayedValue}
        options={options}
        handleClick={handleSelectTime}
      />
    </div>
  );
}
