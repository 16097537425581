import React, { useContext } from 'react';

import Modal from 'react-bootstrap/Modal';

import { UiContext } from '../../context/UiContext';

import classes from './styles.module.scss';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export default function CopyLinkModal({
  show,
  handleClose,
  sharedMeetingAccess,
}) {
  const { notifySuccess } = useContext(UiContext);

  const copyInfo = () => {
    const textToCopy = `To view this prerecorded meeting, click this link: ${
      sharedMeetingAccess.link
    } ${
      sharedMeetingAccess.security === 'restricted'
        ? `and enter this PASSCODE: ${sharedMeetingAccess.passcode}`
        : ''
    }`;

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(textToCopy);
      return;
    }

    navigator.clipboard.writeText(textToCopy);
    notifySuccess(
      `${
        sharedMeetingAccess.security === 'restricted'
          ? 'Link and passcode'
          : 'Link'
      } copied to clipboard`
    );
  };

  const linkType =
    sharedMeetingAccess.security === 'public'
      ? 'public link'
      : 'private link and passcode';

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.CopyLinkModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4858 15.502L12.9836 9.99986L18.4858 4.49768C19.3095 3.67392 19.3095 2.33835 18.4858 1.51458C17.662 0.69081 16.3264 0.69081 15.5027 1.51458L10.0005 7.01675L4.49831 1.51458C3.67455 0.69081 2.33898 0.69081 1.51521 1.51458C0.691438 2.33835 0.691439 3.67392 1.51521 4.49768L7.01738 9.99986L1.51521 15.502C0.691439 16.3258 0.691438 17.6614 1.51521 18.4851C2.33898 19.3089 3.67455 19.3089 4.49831 18.4851L10.0005 12.983L15.5027 18.4851C16.3264 19.3089 17.662 19.3089 18.4858 18.4851C19.3095 17.6614 19.3095 16.3258 18.4858 15.502Z"
            fill="black"
          />
        </svg>
      </i>
      <div className={classes.content}>
        <h3>Here&apos;s your {linkType}</h3>
        <p>
          Click the below card icon to copy and paste your meeting details. Send
          this to people who should view your meeting.
        </p>
        <div className={classes.infoContainer}>
          <div className={classes.info}>
            <a href={sharedMeetingAccess.link} target="_blank" rel="noreferrer">
              LINK
            </a>
            {sharedMeetingAccess.security === 'restricted' && (
              <span>
                <b>PASSCODE:</b> {sharedMeetingAccess.passcode}
              </span>
            )}
          </div>

          <div className={classes.copyIcon} onClick={copyInfo}>
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 115.77 122.88"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs id="defs22" />
              <style type="text/css" id="style13" />
              <g id="g17" transform="matrix(-0.99061203,0,0,1,115.12918,0)">
                <path
                  className="st0"
                  d="m 89.62,13.96 v 7.73 h 12.19 0.01 v 0.02 c 3.85,0.01 7.34,1.57 9.86,4.1 2.5,2.51 4.06,5.98 4.07,9.82 h 0.02 v 0.02 73.27 0.01 h -0.02 c -0.01,3.84 -1.57,7.33 -4.1,9.86 -2.51,2.5 -5.98,4.06 -9.82,4.07 v 0.02 h -0.02 -61.7 -0.01 v -0.02 c -3.84,-0.01 -7.34,-1.57 -9.86,-4.1 -2.5,-2.51 -4.06,-5.98 -4.07,-9.82 H 26.15 V 108.92 92.51 H 13.96 13.95 V 92.49 C 10.11,92.48 6.61,90.92 4.09,88.39 1.59,85.88 0.03,82.41 0.02,78.57 H 0 V 78.55 13.96 13.95 H 0.02 C 0.03,10.1 1.6,6.61 4.12,4.09 6.63,1.59 10.1,0.03 13.94,0.02 V 0 h 0.02 61.7 0.01 v 0.02 c 3.85,0.01 7.34,1.57 9.86,4.1 2.5,2.51 4.06,5.98 4.07,9.82 h 0.02 z m -10.58,7.73 v -7.73 -0.02 h 0.02 c 0,-0.91 -0.39,-1.75 -1.01,-2.37 -0.61,-0.61 -1.46,-1 -2.37,-1 v 0.02 h -0.01 -61.7 -0.02 v -0.02 c -0.91,0 -1.75,0.39 -2.37,1.01 -0.61,0.61 -1,1.46 -1,2.37 h 0.02 v 0.01 64.59 0.02 h -0.02 c 0,0.91 0.39,1.75 1.01,2.37 0.61,0.61 1.46,1 2.37,1 v -0.02 h 0.01 12.19 V 35.65 35.64 h 0.02 c 0.01,-3.85 1.58,-7.34 4.1,-9.86 2.51,-2.5 5.98,-4.06 9.82,-4.07 v -0.02 h 0.02 z m 26.14,87.23 V 35.65 35.63 h 0.02 c 0,-0.91 -0.39,-1.75 -1.01,-2.37 -0.61,-0.61 -1.46,-1 -2.37,-1 v 0.02 h -0.01 -61.7 -0.02 v -0.02 c -0.91,0 -1.75,0.39 -2.37,1.01 -0.61,0.61 -1,1.46 -1,2.37 h 0.02 v 0.01 73.27 0.02 h -0.02 c 0,0.91 0.39,1.75 1.01,2.37 0.61,0.61 1.46,1 2.37,1 v -0.02 h 0.01 61.7 0.02 v 0.02 c 0.91,0 1.75,-0.39 2.37,-1.01 0.61,-0.61 1,-1.46 1,-2.37 h -0.02 z"
                  id="path15"
                  fill="#545982"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </Modal>
  );
}
