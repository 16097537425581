import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import moment from 'moment';

import closeIcon from '../../assets/images/closeIcon.svg';
import { timezones } from './timezones';
import localTimeZone from '../../helpers/localTimeZone';
import Select from '../../components/Select';
import classes from './styles.module.scss';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

export default function SelectTimeZoneModal({
  show,
  handleClose,
  timeZoneAssignment,
  selectedTimeZone,
  setSelectedTimeZone,
}) {
  const [currentTimeZone, setCurrentTimeZone] = useState(null);

  useEffect(() => {
    if (timeZoneAssignment === 'schedule') {
      setCurrentTimeZone(selectedTimeZone.schedule);
    } else {
      setCurrentTimeZone(selectedTimeZone.expiration);
    }
  }, [selectedTimeZone, show, timeZoneAssignment]);

  const timezoneOffset = moment
    .tz(Date.now(), currentTimeZone?.value)
    .format('Z');
  const displayedValue = timezones[timezoneOffset]?.label;

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.SelectTimeZoneModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <p>Select timezone</p>
        <Select
          displayedValue={displayedValue}
          options={Object.values(timezones)}
          handleClick={(option) => {
            setCurrentTimeZone(timezones[option.offset]);
          }}
          textAlign="flex-start"
        />
        <div className={classes.buttons}>
          <button
            type="button"
            onClick={() =>
              setCurrentTimeZone({
                label: '',
                value: localTimeZone,
              })
            }
          >
            Use current time zone
          </button>
          <div className={classes.confirmContainer}>
            <ConfirmButton
              onClick={() => {
                handleClose();

                setSelectedTimeZone((prevTimeZone) =>
                  timeZoneAssignment === 'schedule'
                    ? { ...prevTimeZone, schedule: currentTimeZone }
                    : { ...prevTimeZone, expiration: currentTimeZone }
                );
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
