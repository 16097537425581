import React, { useState } from 'react';

import classNames from 'classnames';

import { STATIC_URL } from '../../../../constants/main';
import usericon from '../../../../assets/images/usericon.webp';

export default function UserIcon({ creatorPhotoPath, creatorName, classes }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={classNames(classes.usericon, {
        [classes.withAvatar]: !!creatorPhotoPath,
      })}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={creatorPhotoPath ? `${STATIC_URL}${creatorPhotoPath}` : usericon}
        alt="usericon"
      />
      {isHovered && (
        <div
          className={classNames(classes.username, {
            [classes.defaultIcon]: !creatorPhotoPath,
          })}
        >
          {creatorName}
        </div>
      )}
    </div>
  );
}
