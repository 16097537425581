import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import Picker from '@emoji-mart/react';

import classes from './styles.module.scss';

export default function EmojiPicker({
  isEmojiPickerVisible,
  setIsEmojiPickerVisible,
  setText,
  emojiPickerRef,
  pickerTop,
  pickerLeft,
  pickerBottom,
  pickerRight,
}) {
  return (
    isEmojiPickerVisible && (
      <div
        className={classes.emojiPickerContainer}
        ref={emojiPickerRef}
        style={{
          left: pickerLeft,
          top: pickerTop,
          pickerRight,
          pickerBottom,
        }}
      >
        <Picker
          theme="light"
          onEmojiSelect={(emoji) => {
            setText((prevText) => prevText + emoji.native);
            setIsEmojiPickerVisible(false);
          }}
        />
      </div>
    )
  );
}
