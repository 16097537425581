import axios from 'axios';

const SectionService = {
  async getSections({ meetingId }) {
    const response = await axios.get('/sections', {
      params: {
        meetingId,
      },
    });
    return response.data;
  },

  async uploadAudioImage({ image, sectionId }) {
    const form = new FormData();
    form.append('file', image);

    const response = await axios.post(
      `/sections/${sectionId}/audio-image`,
      form
    );
    return response.data;
  },

  async createSection({
    title,
    meetingId,
    file,
    question,
    container /* mp4 or webm */,
    image,
    text,
  }) {
    let data;

    if (file) {
      data = new FormData();
      data.append('meetingId', meetingId);
      data.append('file', file, `${Math.random()}.${container}`);
      const fileType = file.type.includes('video') ? 'video' : 'audio';
      data.append('fileType', fileType);
      data.append('title', title);
    } else if (question) {
      data = { question, meetingId, title };
    } else if (text) {
      data = { meetingId, title, text };
    }

    const response = await axios.post(`/sections`, data);

    if (image) {
      const form = new FormData();
      form.append('file', image);
      return SectionService.uploadAudioImage({
        image,
        sectionId: response.data.id,
      });
    }
    return response.data;
  },

  async deleteSection({ sectionId }) {
    const response = await axios.delete(`/sections/${sectionId}`);
    return response.data;
  },

  async updateSection({ sectionId, order, question, note, title }) {
    const response = await axios.patch(`/sections/${sectionId}`, {
      order,
      question,
      note,
      title,
    });
    return response.data;
  },
};

export default SectionService;
