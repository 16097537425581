/* eslint-disable no-param-reassign */
import axios from 'axios';
import { fetch } from 'whatwg-fetch';

import { getAuthData, saveAuthData, clearAuthData } from './authStorage';
import { API_URL } from '../constants/main';
import localTimeZone from './localTimeZone';

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
  (config) => {
    const customConfig = { ...config };
    const { accessToken } = getAuthData();
    if (accessToken) {
      customConfig.headers.Authorization = `${accessToken}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return customConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken, userId } = getAuthData();
      if (!refreshToken) {
        return Promise.reject(error);
      }
      return fetch(`${API_URL}/auth-token-refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken,
          userId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.accessToken) {
            saveAuthData({
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
              userId: res.userId,
            });
            axios.defaults.headers.common.Authorization = `${
              getAuthData().accessToken
            }`;
            return axios(originalRequest);
          }
          return Promise.reject(res);
        })
        .catch((refreshTokenResponse) => {
          clearAuthData();

          document.location.href = '/sign-in';
          return Promise.reject(refreshTokenResponse);
        });
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line consistent-return
export async function signUp(email, password, name, companyName, title) {
  try {
    const response = await axios.post(`/users`, {
      email,
      password,
      name,
      companyName,
      title,
      timezone: localTimeZone,
    });
    saveAuthData(response.data);
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.message);
  }
}

export async function login(email, password, socket) {
  try {
    const response = await axios.post(`/login`, {
      email,
      password,
      timezone: localTimeZone,
    });
    saveAuthData(response.data);
    socket.auth.token = response.data.accessToken;
    socket.disconnect().connect();
  } catch (error) {
    console.log(error);
    throw new Error(error?.response?.data?.message);
  }
}

export function logout(callback) {
  clearAuthData();
  if (callback) {
    callback();
  }
}
