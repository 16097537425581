import React from 'react';

import classes from './styles.module.scss';

export default function CancelButton({ isDisabled, onClick }) {
  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={classes.CancelButton}
      onClick={onClick}
    >
      <svg
        width="117"
        height="135"
        viewBox="0 0 117 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_122_1101)">
          <circle cx="58.5" cy="42.5" r="42.5" fill="#F20000" />
        </g>
        <line
          x1="44.5355"
          y1="28.4414"
          x2="73.5581"
          y2="57.464"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
        />
        <line
          x1="73.5134"
          y1="29.5353"
          x2="44.5352"
          y2="57.7889"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
        />
        <defs>
          <filter
            id="filter0_d_122_1101"
            x="0"
            y="0"
            width="117"
            height="135"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="18"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_122_1101"
            />
            <feOffset dy="34" />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.296667 0 0 0 0 0.741667 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_122_1101"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_122_1101"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  );
}
