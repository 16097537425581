import React from 'react';

import Modal from 'react-bootstrap/Modal';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ConfirmButton from '../../components/Buttons/ConfirmButton';

import classes from './styles.module.scss';

export default function ViewerNotesModal({ show, handleClose, notes, resume }) {
  const closeModalAndResumePlayback = () => {
    handleClose();
    resume();
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ViewerNotesModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.notesContainer}>
        <div
          className={classes.notes}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      </div>
      <footer className={classes.footer}>
        <div className={classes.buttons}>
          <ConfirmButton onClick={closeModalAndResumePlayback} />
        </div>
      </footer>
    </Modal>
  );
}
