import axios from 'axios';

const NotificationService = {
  async getNotifications() {
    const response = await axios.get('/notifications');
    return response.data;
  },
  async deleteNotification(id) {
    const response = await axios.delete(`/notifications/${id}`);
    return response.data;
  },
  async clearNotifications() {
    const response = await axios.delete('/notifications/clear');
    return response.data;
  },
  async checkNotifications() {
    const response = await axios.patch('/notifications/check-all');
    return response.data;
  },
};

export default NotificationService;
