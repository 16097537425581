import React, { useState, useRef } from 'react';

import EmojiPicker from '../EmojiPicker';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import smileyFace from '../../assets/images/view-meeting/smiley-face.svg';
import classes from './styles.module.scss';

export default function EmojiPickerWithButton({
  setCommentText,
  buttonTop,
  buttonLeft,
  buttonBottom,
  buttonRight,
  pickerTop,
  pickerLeft,
  pickerBottom,
  pickerRight,
}) {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const emojiPickerRef = useRef();
  const emojiButtonRef = useRef();
  useOnClickOutside(
    emojiButtonRef,
    () => setIsEmojiPickerVisible(false),
    emojiPickerRef
  );

  return (
    <div
      className={classes.EmojiPickerWithButton}
      style={{
        left: buttonLeft,
        top: buttonTop,
        bottom: buttonBottom,
        right: buttonRight,
      }}
    >
      <EmojiPicker
        setText={setCommentText}
        isEmojiPickerVisible={isEmojiPickerVisible}
        setIsEmojiPickerVisible={setIsEmojiPickerVisible}
        emojiPickerRef={emojiPickerRef}
        pickerTop={pickerTop}
        pickerLeft={pickerLeft}
        pickerBottom={pickerBottom}
        pickerRight={pickerRight}
      />
      <button
        ref={emojiButtonRef}
        type="button"
        className={classes.emojiButton}
        onClick={() => setIsEmojiPickerVisible((prev) => !prev)}
      >
        <img src={smileyFace} alt="Emoji" />
      </button>
    </div>
  );
}
