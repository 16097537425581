import React, { useState, useRef } from 'react';

import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import classNames from 'classnames';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function DatePicker({ date, setDate, disabled }) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const datePickerRef = useRef();

  useOnClickOutside(datePickerRef, () => setIsCalendarVisible(false));

  // eslint-disable-next-line no-shadow
  const changeDate = (date) => {
    if (date < new Date()) {
      setDate(new Date());
    } else {
      setDate(date);
    }

    setIsCalendarVisible(false);
  };

  let displayedValue = 'Select date';

  if (date) {
    displayedValue = moment(date).format('MMM D, YYYY');
  }

  if (disabled) {
    displayedValue = '-';
  }

  return (
    <div className={classes.DatePicker} ref={datePickerRef}>
      <div
        className={classNames(classes.selector, {
          [classes.disabled]: disabled,
        })}
        onClick={() => {
          if (disabled) {
            return;
          }
          setIsCalendarVisible((prevState) => !prevState);
        }}
      >
        {displayedValue}
      </div>
      {isCalendarVisible && (
        <div className={classes.calendarContainer}>
          <Calendar calendarType="US" onChange={changeDate} value={date} />
        </div>
      )}
    </div>
  );
}
