import axios from 'axios';

import { hasAuthData } from '../helpers/authStorage';

const MeetingService = {
  async updateMeeting({ title, status, notes, meetingId }) {
    const response = await axios.patch(`/meetings/${meetingId}`, {
      title,
      status,
      notes,
    });

    return response.data;
  },

  async getMeeting(id) {
    const url = hasAuthData() ? `/meetings/${id}` : `/meetings/public/${id}`;

    const response = await axios.get(url);
    return response.data;
  },

  async getMeetings(page) {
    const response = await axios.get('/meetings', {
      params: {
        page,
      },
    });

    return response.data;
  },

  async createMeeting({ title }) {
    const response = await axios.post('/meetings', { title });
    return response.data;
  },

  async deleteMeeting(meetingId) {
    const response = await axios.delete(`/meetings/${meetingId}`);
    return response.data;
  },

  async assignSection({ meetingId, email, message }) {
    const response = await axios.post(`/meetings/${meetingId}/assign-section`, {
      email,
      message,
    });

    return response.data;
  },

  async closeAssignedSection(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/close-section`);
    return response.data;
  },

  async previewMeeting(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/preview`);
    return response.data;
  },

  async confirmMeetingView(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/confirm-view`);
    return response.data;
  },

  async confirmMeetingViewAnonymously({ meetingId, email }) {
    const response = await axios.post(
      `/meetings/${meetingId}/confirm-view/anon`,
      { email }
    );
    return response.data;
  },

  async uploadAudioImage({ image, meetingId }) {
    const form = new FormData();
    form.append('file', image);

    const response = await axios.post(
      `/meetings/${meetingId}/audio-image`,
      form
    );
    return response.data;
  },

  async scheduleMeetingShare({
    meetingId,
    security,
    email,
    activatesAt,
    expiresAt,
    message,
  }) {
    const response = await axios.post(`/meetings/${meetingId}/share/schedule`, {
      security,
      email,
      activatesAt,
      expiresAt,
      message,
    });
    return response.data;
  },
  async shareMeetingLink({ meetingId, security, expiresAt }) {
    const response = await axios.post(`/meetings/${meetingId}/share/link`, {
      security,
      expiresAt,
    });
    return response.data;
  },
};

export default MeetingService;
