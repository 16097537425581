import React, { useEffect, useState } from 'react';

import classes from './styles.module.scss';

export default function SavingSectionModal({ videoPlayerRef }) {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (videoPlayerRef.current) {
      const coords = videoPlayerRef.current?.getBoundingClientRect();
      const videoWidth = videoPlayerRef.current?.clientWidth;
      const videoHeight = videoPlayerRef.current?.clientHeight;
      setLeft(coords.left + (videoWidth - 622) / 2);
      setTop(coords.top + (videoHeight - 240) / 2);
    }
  }, [videoPlayerRef]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <div className={classes.SavingSectionModal}>
      <div className={classes.content} style={{ left, top }}>
        <span className={classes.heading}>Hang tight!</span>
        <span className={classes.message}>We are saving your section</span>
      </div>
    </div>
  );
}
