import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';

import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Notification from '../../components/Notification';
import ConfirmActionModal from '../../modals/ConfirmActionModal';
import NotificationService from '../../services/NotificationService';
import classes from './styles.module.scss';

export default function NotificationsPage() {
  const [
    isClearNotificationsModalVisible,
    setIsClearNotificationsModalVisible,
  ] = useState(false);

  const { data, refetch } = useQuery(
    'notifications',
    NotificationService.getNotifications
  );

  const { mutate: clearNotifications } = useMutation(
    NotificationService.clearNotifications,
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div className={classes.NotificationsPage}>
      <Header hasUserBlock />
      <div className={classes.container}>
        <Navigation />
        {!!data?.length && (
          <button
            type="button"
            className={classes.clearButton}
            onClick={() => setIsClearNotificationsModalVisible(true)}
          >
            Clear all notifications
          </button>
        )}
        <div className={classes.notifications}>
          {data?.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              refetch={refetch}
            />
          ))}
        </div>

        {!data?.length && (
          <p className={classes.noNotifications}>No notifications yet</p>
        )}
      </div>

      <ConfirmActionModal
        title="Are you sure you want to clear?"
        show={isClearNotificationsModalVisible}
        handleClose={() => setIsClearNotificationsModalVisible(false)}
        onConfirm={clearNotifications}
      />
    </div>
  );
}
