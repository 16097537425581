import axios from 'axios';

const AnswerService = {
  async createAnswer({ text, questionId }) {
    const response = await axios.post('/answers', {
      questionId,
      text,
    });
    return response.data;
  },
};

export default AnswerService;
