import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import classes from './styles.module.scss';

export default function Navigation() {
  const location = useLocation();

  const isMeetingEditing = location.pathname.includes('/edit');

  return (
    <nav className={classes.Navigation}>
      <NavLink
        className={({ isActive }) =>
          (isActive || isMeetingEditing) && classes.active
        }
        to="/create-meeting"
      >
        Create a meeting
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive && !isMeetingEditing && classes.active
        }
        to="/my-meetings"
      >
        My meetings
      </NavLink>

      <NavLink
        className={({ isActive }) => isActive && classes.active}
        to="/notifications"
      >
        Notifications
      </NavLink>
    </nav>
  );
}
