import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';
import TimeAgo from 'javascript-time-ago';

import ReplyBox from '../ReplyBox';

import editIcon from '../../assets/images/view-meeting/edit.svg';
import deleteIcon from '../../assets/images/view-meeting/delete.svg';
import replyIcon from '../../assets/images/view-meeting/reply.svg';
import likeIcon from '../../assets/images/view-meeting/like.svg';
import CommentService from '../../services/CommentService';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import userIcon from '../../assets/images/usericon.webp';
import { STATIC_URL } from '../../constants/main';
import videoTimeToHMS from '../../helpers/videoTimeToHMS';
import classes from './styles.module.scss';

export default function Comment({
  comment,
  user,
  meetingId,
  createCommentMutation,
  rewindVideoByComment,
  isNested,
  commentId,
  refetch,
  isAuthenticated,
  setIsSignUpPopupVisible,
}) {
  const [isReplyBoxVisible, setIsReplyBoxVisible] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [isCommentMenuVisible, setIsCommentMenuVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [textContent, setTextContent] = useState('');

  const replyBoxContainerRef = useRef();
  const replyButtonRef = useRef();
  const textareaRef = useRef();
  const commentMenuRef = useRef();
  const commentMenuToggleRef = useRef();
  const commentContentRef = useRef();
  const saveButtonRef = useRef();

  useOnClickOutside(
    replyBoxContainerRef,
    () => setIsReplyBoxVisible(false),
    replyButtonRef
  );

  useOnClickOutside(
    commentMenuRef,
    () => setIsCommentMenuVisible(false),
    commentMenuToggleRef
  );

  useOnClickOutside(
    commentContentRef,
    () => {
      setIsEditMode(false);
      commentContentRef.current.textContent = comment.textContent;
    },
    saveButtonRef
  );

  const { mutate: toggleLike } = useMutation(CommentService.toggleLike, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
      // notifyError(error?.response?.data?.message);
    },
  });

  const { mutate: deleteComment } = useMutation(CommentService.deleteComment, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
      // notifyError(error?.response?.data?.message);
    },
  });

  const { mutate: updateComment } = useMutation(CommentService.updateComment, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
      // notifyError(error?.response?.data?.message);
    },
  });

  const createComment = () => {
    if (commentText.trim()) {
      createCommentMutation({
        textContent: commentText,
        commentId: isNested ? commentId : comment.id,
        meetingId,
      });
      setIsReplyBoxVisible(false);
    }
  };

  useEffect(() => {
    if (isReplyBoxVisible) {
      replyBoxContainerRef.current.scrollIntoView();
    }
  }, [isReplyBoxVisible]);

  const timeAgo = new TimeAgo('en-US');

  return (
    <>
      <div className={classes.Comment}>
        <div className={classes.userpic}>
          <img
            src={
              comment?.User?.photoPath
                ? STATIC_URL + comment.User.photoPath
                : userIcon
            }
            alt="Userpic"
          />
        </div>
        <div className={classes.content}>
          <header>
            <div className={classes.userInfo}>
              <h1>{comment?.User.name}</h1>
              <h2>{comment?.User.title}</h2>
            </div>
            <div>
              <div className={classes.headerRight}>
                {!isEditMode && (
                  <div className={classes.timestamps}>
                    <OverlayTrigger
                      placement="top-end"
                      overlay={<Tooltip>Time since comment was posted</Tooltip>}
                    >
                      <span>
                        {timeAgo.format(
                          new Date(comment.createdAt),
                          'mini-now'
                        )}
                      </span>
                    </OverlayTrigger>
                    {!isNested && (
                      <>
                        <span>|</span>
                        <OverlayTrigger
                          placement="bottom-start"
                          overlay={
                            <Tooltip>
                              Time stamp when comment was made in video
                            </Tooltip>
                          }
                        >
                          <span
                            className={classes.meetingTime}
                            onClick={() => {
                              rewindVideoByComment(comment.meetingTime);
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                          >
                            {videoTimeToHMS(comment.meetingTime)}
                          </span>
                        </OverlayTrigger>
                      </>
                    )}
                  </div>
                )}
                <span
                  ref={commentMenuToggleRef}
                  onClick={() =>
                    setIsCommentMenuVisible((prevState) => !prevState)
                  }
                  className={classes.menuToggle}
                  style={{
                    visibility:
                      comment.userId === user?.id ? 'visible' : 'hidden',
                  }}
                >
                  ...
                </span>
              </div>
              {isCommentMenuVisible && (
                <div className={classes.commentMenu} ref={commentMenuRef}>
                  <ul>
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          setIsEditMode(true);
                          setIsCommentMenuVisible(false);
                          setTimeout(() => {
                            commentContentRef.current.focus();
                          }, 0);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={editIcon} alt="Edit" />
                        </span>
                        <span>Edit</span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          deleteComment({ commentId: comment.id });
                          setIsCommentMenuVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={deleteIcon} alt="Delete" />
                        </span>
                        <span>Delete</span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </header>
          <div
            className={classNames(
              classes.textContent,
              isEditMode && classes.editable
            )}
          >
            <div
              contentEditable={isEditMode}
              ref={commentContentRef}
              onInput={(event) => setTextContent(event.target.textContent)}
            >
              {comment.textContent}
            </div>
          </div>
        </div>
        <div className={classes.commentActions}>
          {isEditMode && (
            <button
              className={classes.saveButton}
              type="button"
              ref={saveButtonRef}
              onClick={() => {
                if (textContent.trim()) {
                  updateComment({ commentId: comment.id, textContent });
                }
                setIsEditMode(false);
              }}
            >
              Save
            </button>
          )}
          <div className={classes.likesContainer}>
            <button
              type="button"
              onClick={() => {
                if (!user) {
                  setIsSignUpPopupVisible(true);
                  return;
                }

                toggleLike({ commentId: comment.id });
              }}
            >
              <img src={likeIcon} alt="Like" /> Like
            </button>
          </div>
          <>
            <span className={classes.divider}>|</span>
            <button
              ref={replyButtonRef}
              type="button"
              onClick={() => {
                if (!user) {
                  setIsSignUpPopupVisible(true);
                  return;
                }

                setIsReplyBoxVisible((prevState) => !prevState);
              }}
            >
              <img src={replyIcon} alt="Reply" /> Reply
            </button>
          </>
        </div>
      </div>
      {isReplyBoxVisible && (
        <div className={classes.replyBoxContainer} ref={replyBoxContainerRef}>
          <ReplyBox
            textareaRef={textareaRef}
            user={user}
            commentText={commentText}
            setCommentText={setCommentText}
            createComment={createComment}
            setIsSignUpPopupVisible={setIsSignUpPopupVisible}
          />
        </div>
      )}
      <div className={classes.replies}>
        {comment?.Reply?.map((reply) => {
          return (
            <Comment
              key={reply.id}
              comment={reply}
              user={user}
              meetingId={meetingId}
              createCommentMutation={createCommentMutation}
              isNested
              commentId={comment.id}
              refetch={refetch}
              isAuthenticated={isAuthenticated}
              setIsSignUpPopupVisible={setIsSignUpPopupVisible}
            />
          );
        })}
      </div>
    </>
  );
}
