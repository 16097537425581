/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import validateEmail from '../../helpers/validateEmail';

import classes from './styles.module.scss';
import MeetingService from '../../services/MeetingService';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import RoundedInput from '../../components/Inputs/RoundedInput';
import Textarea from '../../components/Textarea';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';

export default function AssignSectionModal({
  show,
  handleClose,
  meetingId,
  createMeeting,
  meetingTitle,
  refetchSections,
  setMeetingId,
  setIsAssignSectionPopupVisible,
  setAssignSectionErrorMessage,
}) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const resetForm = () => {
    handleClose();
    setIsSubmitted(false);
    setEmail('');
    setMessage('');
  };

  const submitForm = async () => {
    try {
      let id = meetingId;

      if (!id) {
        const meeting = await createMeeting({ title: meetingTitle });
        id = meeting.id;
        setMeetingId(id);
        window.history.replaceState(
          null,
          'Social Meeting',
          `/my-meetings/${id}/edit`
        );
      }

      await MeetingService.assignSection({
        meetingId: id,
        email,
        message,
      });

      refetchSections();
      setIsSubmitted(true);
      setTimeout(() => {
        resetForm();
      }, 2500);
    } catch (error) {
      console.log(error);
      setAssignSectionErrorMessage(
        error?.response?.data?.message || 'Something went wrong'
      );
      setIsAssignSectionPopupVisible(true);
      handleClose();
    }
  };

  const emailChangedHandler = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.AssignSectionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      {isSubmitted && (
        <div className={classes.successOverlay}>
          <div className={classes.popup}>
            <i className={classes.closeIcon} onClick={handleClose}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4858 15.502L12.9836 9.99986L18.4858 4.49768C19.3095 3.67392 19.3095 2.33835 18.4858 1.51458C17.662 0.69081 16.3264 0.69081 15.5027 1.51458L10.0005 7.01675L4.49831 1.51458C3.67455 0.69081 2.33898 0.69081 1.51521 1.51458C0.691438 2.33835 0.691439 3.67392 1.51521 4.49768L7.01738 9.99986L1.51521 15.502C0.691439 16.3258 0.691438 17.6614 1.51521 18.4851C2.33898 19.3089 3.67455 19.3089 4.49831 18.4851L10.0005 12.983L15.5027 18.4851C16.3264 19.3089 17.662 19.3089 18.4858 18.4851C19.3095 17.6614 19.3095 16.3258 18.4858 15.502Z"
                  fill="black"
                />
              </svg>
            </i>
            <b>Section Assigned!</b>
            <span>
              You will be notified when <br /> collaborator completes section(s)
            </span>
          </div>
        </div>
      )}
      <i
        className={classes.closeIcon}
        onClick={() => {
          handleClose();
          resetForm();
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4858 15.502L12.9836 9.99986L18.4858 4.49768C19.3095 3.67392 19.3095 2.33835 18.4858 1.51458C17.662 0.69081 16.3264 0.69081 15.5027 1.51458L10.0005 7.01675L4.49831 1.51458C3.67455 0.69081 2.33898 0.69081 1.51521 1.51458C0.691438 2.33835 0.691439 3.67392 1.51521 4.49768L7.01738 9.99986L1.51521 15.502C0.691439 16.3258 0.691438 17.6614 1.51521 18.4851C2.33898 19.3089 3.67455 19.3089 4.49831 18.4851L10.0005 12.983L15.5027 18.4851C16.3264 19.3089 17.662 19.3089 18.4858 18.4851C19.3095 17.6614 19.3095 16.3258 18.4858 15.502Z"
            fill="black"
          />
        </svg>
      </i>
      <div className={classes.form}>
        <h1>Invite other users to create content for your meeting</h1>
        <RoundedInput
          placeholder="Add email address"
          type="email"
          value={email}
          onChange={emailChangedHandler}
        />
        <div className={classes.messageContainer}>
          <Textarea
            placeholder="Add message..."
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />

          <EmojiPickerWithButton
            setCommentText={setMessage}
            buttonBottom="24px"
            buttonRight="24px"
            pickerTop="52px"
          />
        </div>

        <footer className={classes.footer}>
          <ConfirmButton
            isDisabled={!email.length || !validateEmail(email)}
            onClick={submitForm}
          />
        </footer>
      </div>
    </Modal>
  );
}
