import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UiContext } from '../../context/UiContext';
import { hasAuthData } from '../../helpers/authStorage';

export default function PrivateRoute({ children }) {
  const isAuthenticated = hasAuthData();
  const location = useLocation();

  const { setRedirectAfterLogin } = useContext(UiContext);

  if (!isAuthenticated) {
    setRedirectAfterLogin(location.pathname);
  }

  return isAuthenticated ? children : <Navigate to="/sign-in" />;
}
