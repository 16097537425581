/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import classNames from 'classnames';

import noteIcon from '../../assets/images/section-menu/notes.svg';
import audioPlaceholder from '../../assets/images/section-menu/audio-placeholder.png';
import questionSection from '../../assets/images/section-menu/question-section.png';
import useResizeObserver from '../../hooks/useResizeObserver';
import { STATIC_URL } from '../../constants/main';
import Tooltip from '../Tooltip';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';

export default function PreviewSections({
  data,
  videoRef,
  animateProgressBar,
  previewSectionsRef,
  setQuestionToShow,
  setIsQuestionModalVisible,
  setPreviousShownQuestions,
  setPreviousShownNote,
}) {
  const [sectionsPageCount, setSectionsPageCount] = useState(0);
  const [currentSectionsPage, setCurrentSectionsPage] = useState(0);
  const [sectionsStartPoints, setSectionsStartPoints] = useState([]);

  const { showViewerNotesModal } = useContext(UiContext);

  const sectionsListContainerRef = useRef();
  const leftSwitchRef = useRef();

  const { width } = useResizeObserver(sectionsListContainerRef);

  const sectionsCount = Math.floor(width / (184 + 16)) || 1;

  const scrollSections = (direction) => {
    if (direction === 'right') {
      if (currentSectionsPage === 0) {
        return;
      }
      setCurrentSectionsPage((prevPage) => prevPage - 1);
    } else {
      if (sectionsPageCount === currentSectionsPage) {
        return;
      }
      setCurrentSectionsPage((prevPage) => prevPage + 1);
    }
  };

  const handleRewindToSection = useCallback(
    (section, order) => {
      if (section.Question) {
        setQuestionToShow(section.Question);
        setIsQuestionModalVisible(true);
        videoRef.current.pause();
      } else {
        setPreviousShownQuestions([]);
      }

      if (section.type === 'note') {
        showViewerNotesModal(section.text, () => videoRef.current.play());
        setPreviousShownNote(section);
      } else {
        setPreviousShownNote({});
      }

      const sectionTimeCode = sectionsStartPoints[order] - section.duration;

      if (order === 0) {
        videoRef.current.currentTime = 0;
      } else {
        videoRef.current.currentTime = sectionTimeCode + 0.1;
      }

      animateProgressBar();
    },
    [animateProgressBar, sectionsStartPoints, videoRef]
  );

  useEffect(() => {
    setSectionsPageCount(Math.max(data?.Sections.length - sectionsCount, 0));
  }, [data?.Sections, sectionsCount]);

  useEffect(() => {
    const preparedSectionsStartPoints = [];
    let currentSectionStartPoint = 0;

    data?.Sections.forEach((section) => {
      if (section.Question || section.type === 'note') {
        if (section.Question) {
          preparedSectionsStartPoints.push(section.Question.timestamp);
        } else {
          preparedSectionsStartPoints.push(section.timestamp);
        }
        return;
      }

      currentSectionStartPoint += section.duration;
      preparedSectionsStartPoints.push(currentSectionStartPoint);
    });

    setSectionsStartPoints(preparedSectionsStartPoints);
  }, [data]);

  return (
    <div className={classes.sections} ref={previewSectionsRef}>
      <div
        className={classNames(classes.switchLeft, classes.switch)}
        onClick={() => scrollSections('right')}
        ref={leftSwitchRef}
      />
      <div
        className={classNames(classes.switchRight, classes.switch)}
        onClick={() => scrollSections('left')}
      />
      <div
        className={classes.sectionsListContainer}
        ref={sectionsListContainerRef}
      >
        <ul
          className={classes.sectionsList}
          style={{ width: (184 + 16) * sectionsCount }}
        >
          <div
            className={classes.inner}
            style={{
              left: currentSectionsPage * -(184 + 16),
            }}
          >
            {data?.Sections.map((section, index) => {
              let sectionContent = (
                <div className={classes.sectionContent}>
                  <video src={STATIC_URL + section?.path} />
                </div>
              );

              if (section.type === 'assigned-section') {
                return;
              }

              if (section.type === 'audio') {
                sectionContent = (
                  <div className={classes.sectionContent}>
                    <img src={audioPlaceholder} alt="Audio" />
                  </div>
                );
              }

              if (section.type === 'question') {
                sectionContent = (
                  <div className={classes.sectionContent}>
                    <img src={questionSection} alt="Question" />
                  </div>
                );
              }

              if (section.type === 'note') {
                sectionContent = (
                  <div
                    className={classNames(
                      classes.sectionContent,
                      classes.noteSectionContent
                    )}
                  >
                    <img src={noteIcon} alt="Note" />
                  </div>
                );
              }

              return (
                <li
                  key={section.id}
                  className={classNames(classes.sectionItem)}
                  onClick={() => handleRewindToSection(section, index)}
                >
                  {sectionContent}
                  <Tooltip text={section.title} textAlign="center" width={300}>
                    <div className={classes.title}>
                      <span>{section.title}</span>
                    </div>
                  </Tooltip>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
}
