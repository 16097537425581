import React, { useState, useEffect, useCallback } from 'react';

import closeSvg from '../../../assets/images/closeIcon.svg';
import Checkbox from '../../Checkbox';

import classes from './styles.module.scss';

export default function BigPopup({
  title,
  message,
  closeIcon,
  onClose,
  timeToClose,
  width,
  height,
  textWidth,
  style,
  backdropColor,
  variant,
  onConfirm,
  onCancel,
  confirmButtonLabel,
  cancelButtonLabel,
  canBeDisabled,
  name,
}) {
  const [isPopupDisabled, setIsPopupDisabled] = useState(false);

  const disablePopup = useCallback(() => {
    let disabledPopups = localStorage.getItem('disabledPopups');

    if (!disabledPopups) {
      disabledPopups = JSON.stringify([name]);
    } else {
      disabledPopups = JSON.parse(disabledPopups);
      disabledPopups.push(name);
      disabledPopups = JSON.stringify(disabledPopups);
    }

    localStorage.setItem('disabledPopups', disabledPopups);
  }, [name]);

  const enablePopup = useCallback(() => {
    let disabledPopups = localStorage.getItem('disabledPopups');

    if (!disabledPopups) {
      return;
    }

    disabledPopups = JSON.parse(disabledPopups);
    disabledPopups = disabledPopups.filter((popup) => popup !== name);
    localStorage.setItem('disabledPopups', JSON.stringify(disabledPopups));
  }, [name]);

  useEffect(() => {
    if (timeToClose) {
      setTimeout(() => {
        onClose?.();
      }, timeToClose);
    }
  }, [onClose, timeToClose]);

  useEffect(() => {
    if (isPopupDisabled) {
      disablePopup();
    } else {
      enablePopup();
    }
  }, [disablePopup, enablePopup, isPopupDisabled]);

  return (
    <div className={classes.BigPopup}>
      <div
        className={classes.backdrop}
        style={{ backgroundColor: backdropColor }}
      />
      <div className={classes.popup} style={{ ...style, width, height }}>
        {closeIcon && (
          <i className={classes.closeIcon} onClick={onClose}>
            <img src={closeSvg} alt="close" />
          </i>
        )}
        <span>{title}</span>
        <p style={{ width: textWidth }}>{message}</p>
        {variant === 'singleButton' && (
          <div className={classes.singleButtonContainer}>
            <button
              type="button"
              className={classes.singleButton}
              onClick={onConfirm}
            >
              {confirmButtonLabel || 'Okay'}
            </button>
          </div>
        )}
        {variant === 'confirm' && (
          <div className={classes.confirmButtonsContainer}>
            {name && canBeDisabled && (
              <Checkbox
                onClick={() => setIsPopupDisabled((prevState) => !prevState)}
                isChecked={isPopupDisabled}
                label="Don't show me again"
                labelStyle={{
                  color: '#545454',
                  fontSize: 16,
                  fontWeight: 'normal',
                }}
              />
            )}
            <button
              type="button"
              className={classes.cancelButton}
              onClick={onCancel}
            >
              {cancelButtonLabel || 'Cancel'}
            </button>
            <button
              type="button"
              className={classes.confirmButton}
              onClick={onConfirm}
            >
              {confirmButtonLabel || 'Okay'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
