import React from 'react';

import classes from './styles.module.scss';

export default function SplitTemplate({ children }) {
  return (
    <div className={classes.SplitTemplate}>
      <div className={classes.leftSide}>{children}</div>

      <div className={classes.rightSide}>
        <div className={classes.info}>
          <h2>Create interactive, prerecorded meetings</h2>
          <p>
            SocialMeeting is the most convenient way to create and host engaging
            meetings - without having to attend them live
          </p>
        </div>
      </div>
    </div>
  );
}
