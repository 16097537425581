import React from 'react';

import classes from './styles.module.scss';

export default function DotsLoader() {
  return (
    <div className={classes.DotsLoader}>
      <div />
      <div />
      <div />
    </div>
  );
}
