/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Option({ isChecked, onClick, children }) {
  return (
    <div
      className={classNames(classes.Option, {
        [classes.active]: isChecked,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
