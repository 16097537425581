import React, { useState, useEffect, useRef } from 'react';

import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';
import { WaveSurfer } from 'wavesurfer-react';

const plugins = [
  {
    plugin: MicrophonePlugin,
  },
];

export default function WaveForm({
  containerRef,
  isRecording,
  source,
  isPlaying,
  isPaused,
  isEnded,
  isAudioEnabled,
  height,
  fullHeight,
  autoPlay,
  seek,
}) {
  const [id] = useState(Math.random().toString().split('.').join(''));

  const wavefsurferRef = useRef();

  useEffect(() => {
    const resizeWaveForm = () => {
      if (height) {
        wavefsurferRef.current?.setHeight?.(height);
      } else if (fullHeight) {
        wavefsurferRef.current?.setHeight?.(containerRef.current?.clientHeight);
      } else {
        wavefsurferRef.current?.setHeight?.(
          containerRef.current?.clientHeight - (isAudioEnabled ? 85 : 75)
        );
      }
    };

    window.addEventListener('resize', resizeWaveForm);
    resizeWaveForm();

    return () => {
      window.removeEventListener('resize', resizeWaveForm);
    };
  }, [containerRef, fullHeight, height, isAudioEnabled]);

  // Fix border bug (?) and move waveform to background
  useEffect(() => {
    const nestedWave = document.body.querySelector(`#i${id} wave wave`);
    const outerWave = document.body.querySelector(`#i${id} wave`);
    if (nestedWave) {
      nestedWave.style.borderRight = 'none';
      outerWave.style.overflowX = 'hidden';
    }

    if (outerWave) {
      outerWave.style.zIndex = 0;
    }
  }, [id]);

  useEffect(() => {
    if (isRecording) {
      wavefsurferRef.current.microphone.start();
    }
  }, [isRecording]);

  useEffect(() => {
    if (source) {
      wavefsurferRef.current.load(source);
      wavefsurferRef.current.on('ready', () => {
        if (autoPlay) {
          wavefsurferRef.current.play();
        }
      });
    }
  }, [autoPlay, source]);

  useEffect(() => {
    if (source && isPlaying) {
      wavefsurferRef.current.play();
    }
  }, [isPlaying, source]);

  useEffect(() => {
    if (source && isEnded) {
      wavefsurferRef.current.stop();
    }
  }, [isEnded, source]);

  useEffect(() => {
    if (source && isPaused) {
      wavefsurferRef.current.pause();
    }
  }, [isPaused, source]);

  useEffect(() => {
    return () => {
      //  wavefsurferRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (seek !== undefined && seek >= 0 && seek <= 1) {
      wavefsurferRef.current.seekTo(seek);
    }
  }, [seek]);

  return (
    <div id={`i${id}`}>
      <WaveSurfer
        plugins={plugins}
        container={`#i${id}`}
        waveColor="white"
        progressColor="#808080"
        barHeight={1}
        barMinHeight={1}
        barGap={null}
        barWidth={3}
        responsive
        interact={false}
        onMount={(wavesurfer) => {
          // const { microphone } = wavesurfer;
          wavefsurferRef.current = wavesurfer;

          // microphone.on('deviceReady', function (stream) {
          //   console.log('Device ready!', stream);
          // });
          // microphone.on('deviceError', function (code) {
          //   console.warn(`Device error: ${code}`);
          // });

          // microphone.start();
        }}
      />
    </div>
  );
}
