import React from 'react';

import closeIcon from '../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';
import ConfirmButton from '../Buttons/ConfirmButton';
import CancelButton from '../Buttons/CancelButton';

export default function ConfirmSectionRemoval({ onHide, onConfirm }) {
  return (
    <div className={classes.ConfirmSectionRemoval}>
      <i className={classes.closeIcon} onClick={onHide}>
        <img src={closeIcon} alt="close" />
      </i>
      <div className={classes.content}>
        <p>Are you sure you want to delete this section?</p>
        <div className={classes.buttons}>
          <CancelButton
            type="button"
            className={classes.cancelButton}
            onClick={onHide}
          />

          <ConfirmButton
            type="button"
            className={classes.yesButton}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
}
